.aws-btn.danger .aws-btn__content {
  background: #f66445 !important;
}
.aws-btn--primary.danger .aws-btn__wrapper:before {
  background: #f66445 !important;
}
.aws-btn {
  --button-default-height: 48px;
  --button-default-font-size: 14px;
  --button-default-border-radius: 12px;
  --button-horizontal-padding: 21px;
  --button-raise-level: 0px;
  --button-hover-pressure: 0;
  --transform-speed: 0.185s;
  --button-primary-color: #000000;
  --button-primary-color-dark: #000000;
  --button-primary-color-light: #000000;
  --button-primary-color-hover: #454545;
  --button-primary-border: none;
  --button-secondary-color: #fafafa;
  --button-secondary-color-dark: #67cbc3;
  --button-secondary-color-light: #349890;
  --button-secondary-color-hover: #ecf9f8;
  --button-secondary-border: 2px solid #b3e5e1;
  --button-anchor-color: #95d44a;
  --button-anchor-color-dark: #52a934;
  --button-anchor-color-light: #3f8228;
  --button-anchor-color-hover: #89cf35;
  --button-anchor-border: 2px solid #5bbd3a;
}




.aws-btn--primary .aws-btn__content {
   
  color: #fff !important;
}


