body {
    margin: 0;
    font-family: Roboto, MetaPro, "Cera Pro";
    color: #262626;
    background: #f9f9fa;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  code {
    font-family: Roboto, MetaPro, source-code-pro, Menlo, Monaco, Consolas,
      "Courier New", monospace;
  }
  .disable-button {
    cursor: not-allowed;
    pointer-events: none;
  }
  @media (min-width: 1200px) {
    .container,
    .container-xl {
      max-width: 100%;
      padding-left: 7.1%;
      padding-right: 7.1%;
    }
  }
  .about-us-sec {
    background-color: #f9f9fa;
    background-position: center bottom;
    background-size: cover;
    position: relative;
    padding: 157px 0;
  }
  @media (max-width: 1599.98px) {
    .about-us-sec {
      padding: 117px 0;
    }
  }
  @media (max-width: 1439.98px) {
    .about-us-sec {
      padding: 88px 0;
    }
  }
  .about-us-sec::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      270deg,
      rgba(0, 0, 0, 0) -7.66%,
      rgba(0, 0, 0, 0.75) 98.82%
    );
    z-index: 1;
  }
  .about-us-sec .container {
    position: relative;
    z-index: 2;
  }
  .about-us-sec .sec-title {
    color: #fff;
    margin-bottom: 22px;
  }
  .about-us-sec p {
    font-size: 17px;
    line-height: 1.47059;
    color: #fff;
    opacity: 0.7;
    margin-bottom: 50px;
    max-width: 23em;
  }
  .BestDestination-section {
    padding: 90px 0 0 0 0;
  }
  @media (max-width: 1599.98px) {
    .BestDestination-section {
      padding: 67px 0 0 0;
    }
  }
  @media (max-width: 1439.98px) {
    .BestDestination-section {
      padding: 50px 0 0 0;
    }
  }
  .OffersYouMayLike-section {
    padding: 80px 0;
  }
  @media (max-width: 1599.98px) {
    .OffersYouMayLike-section {
      padding: 60px 0;
    }
  }
  @media (max-width: 1439.98px) {
    .OffersYouMayLike-section {
      padding: 45px 0;
    }
  }
  .best-traveling-section {
    padding: 90px 0 140px;
  }
  @media (max-width: 1599.98px) {
    .best-traveling-section {
      padding: 67px 0 105px;
    }
  }
  @media (max-width: 1439.98px) {
    .best-traveling-section {
      padding: 50px 0 78px;
    }
  }
  .best-traveling-section img {
    transition: 0.3s;
  }
  .best-traveling-section img:hover {
    transform: scale(1.1);
  }
  .best-traveling-section .sec-title-heading {
    margin-bottom: 10px;
  }
  .package-detail-info .title {
    font-weight: 500;
    font-size: 26px;
    line-height: 48px;
    color: #333;
    margin-bottom: 4px;
  }
  .package-detail-info .location {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 40px;
    padding-left: 18px;
    background-position: left top 5px;
    background-repeat: no-repeat;
    background-image: url(../images/icons/location-dark.svg);
  }
  .package-detail-info .text-content {
    font-weight: normal;
    font-size: 16px;
    line-height: 2;
    color: #000;
  }
  .package-detail-info .text-content iframe {
    width: 100%;
    border: none !important;
  }
  @media (max-width: 991.98px) {
    .package-detail-info .text-content {
      font-size: 14px;
    }
  }
  .package-detail-info .tab-btns {
    display: flex;
    border-bottom: 2px solid rgba(0, 0, 0, 0.05);
    overflow-x: auto;
    position: relative;
    margin-right: -16px;
    margin-left: -16px;
    padding-right: 16px;
    padding-left: 16px;
  }
  .package-detail-info .tab-btns::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    background: #dfe7e8;
  }
  .package-detail-info .tab-btns::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 30px;
  }
  .package-detail-info .tab-btns:hover::-webkit-scrollbar-thumb {
    background: #000;
  }
  .package-detail-info .tab-btns::before {
    content: "";
    position: absolute;
    height: 2px;
    left: 16px;
    bottom: 0;
    background: rgba(0, 0, 0, 0.05);
  }
  .package-detail-info .tab-btns .btn {
    font-size: 18px;
    line-height: 36px;
    color: #343434;
    padding: inherit;
    border: 0;
    outline: none;
    box-shadow: none;
    border-top: 2px solid transparent;
    border-radius: 0;
    white-space: nowrap;
    background-color: transparent;
  }
  .package-detail-info .tab-btns .btn:not(:first-child) {
    margin-left: 20px;
  }
  @media (max-width: 991.98px) {
    .package-detail-info .tab-btns .btn {
      font-size: 16px;
    }
  }
  .package-detail-info .tab-btns .btn:hover,
  .package-detail-info .tab-btns .btn.active {
    color: #28a745;
    position: relative;
    border-top-color: #28a745 !important;
    border-top-width: 2px !important;
  }
  .package-detail-info .content {
    min-height: 180px;
    padding-top: 22px;
  }
  .package-detail-info .content .col-sm-6 {
    width: 50%;
    padding: 5px;
  }
  .package-detail-info .content img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  .adventurous .head {
    margin-bottom: 40px;
  }
  @media (max-width: 1599.98px) {
    .adventurous .head {
      margin-bottom: 30px;
    }
  }
  @media (max-width: 575.98px) {
    .adventurous .head {
      margin-bottom: 52px;
    }
  }
  .adventurous .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 0;
  }
  @media (max-width: 1599.98px) {
    .adventurous .title {
      font-size: 18px;
    }
  }
  .adventurous .view-all {
    padding: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    color: #000;
    opacity: 0.16;
  }
  @media (max-width: 1599.98px) {
    .adventurous .view-all {
      font-size: 12px;
    }
  }
  .adventurous .view-all:hover {
    opacity: 0.3;
  }
  .adventurous .options {
    display: flex;
    justify-content: space-between;
  }
  @media (max-width: 575.98px) {
    .adventurous .options {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    .adventurous .options > div {
      flex: 0 0 33.33%;
      max-width: 33.33%;
    }
  }
  .adventurous .options .btn {
    padding: 0;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    color: #989898;
    font-size: 13px;
    line-height: 16px;
    margin: 0 auto 30px;
  }
  @media (max-width: 1599.98px) {
    .adventurous .options .btn {
      font-size: 12px;
    }
  }
  .adventurous .options .btn img {
    width: 42px;
    height: 42px;
    object-fit: contain;
    margin-bottom: 13px;
  }
  @media (max-width: 1599.98px) {
    .adventurous .options .btn img {
      width: 32px;
      height: 32px;
      margin-bottom: 10px;
    }
  }
  .filepond--credits {
    display: none !important;
  }
  @media only screen and (max-width: 600px) {
    .desktop-only {
      display: none;
    }
    .mobile-only {
      display: block;
    }
  }
  @media only screen and (min-width: 768px) {
    .desktop-only {
      display: block;
    }
    .mobile-only {
      display: none;
    }
  }
  .read-more-btn {
    height: 30px;
    padding: 2px 4px;
    font-size: 14px;
    margin-bottom: 26px;
    margin-right: 10px;
  }
  .sec-title-heading {
    font-weight: bold;
    font-size: 17px;
    line-height: 1.17647;
    text-transform: uppercase;
    color: #5f6062;
    margin-bottom: 10px;
  }
  @media (max-width: 1599.98px) {
    .sec-title-heading {
      font-size: 15px;
    }
  }
  @media (max-width: 1439.98px) {
    .sec-title-heading {
      font-size: 14px;
    }
  }
  .sec-title {
    font-size: 45px;
    line-height: 1.142857;
    font-weight: 700;
  }
  @media (max-width: 1599.98px) {
    .sec-title {
      font-size: 45.5px;
    }
  }
  @media (max-width: 1439.98px) {
    .sec-title {
      font-size: 40px;
    }
  }
  @media (max-width: 991.98px) {
    .sec-title {
      font-size: 32px;
    }
  }
  @media (max-width: 767.98px) {
    .sec-title {
      font-size: 22px;
    }
  }
  #header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    transition: 0.3s;
    display: flex;
    align-items: center;
  }
  #header.isSticky {
    height: 100px;
    background-color: #262626;
  }
  @media (max-width: 1599.98px) {
    #header.isSticky {
      height: 80px;
    }
  }
  @media (max-width: 767.98px) {
    #header.isSticky {
      height: 60px;
    }
  }
  #header .container,
  #header .container-xl {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  #header .logo img {
    max-height: 100px;
    padding-bottom: 5px;
  }
  @media (max-width: 1599.98px) {
    #header .logo img {
      height: 90px;
    }
  }
  @media (max-width: 575.98px) {
    #header .logo img {
      height: 70px;
    }
  }
  @media (max-width: 375px) {
    #header .logo img {
      height: 64px;
    }
  }
  #header .logo .container,
  #header .logo .container-xl {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  #header .logo .logo img {
    max-height: 100px;
    padding-bottom: 0px;
  }
  @media (max-width: 1599.98px) {
    #header .logo .logo img {
      height: 100px;
    }
  }
  @media (max-width: 575.98px) {
    #header .logo .logo img {
      height: 80px;
    }
  }
  @media (max-width: 375px) {
    #header .logo .logo img {
      height: 75px;
    }
  }
  #header .menu-btn {
    background: transparent;
    border: 0;
    padding: 0;
    margin-left: 16px;
  }
  #header .menu-bg {
    z-index: 99;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(38, 38, 38, 0.7);
    transform: translateX(-100%);
    backdrop-filter: blur(3px);
  }
  #header .menu-bg.active {
    transform: translateX(0);
  }
  @media (max-width: 991.98px) {
    #header .menus {
      transition: 0.3s;
      position: fixed;
      z-index: 998;
      height: 100vh;
      top: 0;
      left: 0;
      background: #262626;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 242px;
      transform: translateX(-100%);
    }
    #header .menus.active {
      transform: translateX(0);
    }
  }
  #header .menus ul {
    margin: 0;
    padding: 0;
    display: flex;
  }
  @media (max-width: 991.98px) {
    #header .menus ul {
      flex-direction: column;
    }
  }
  #header .menus ul li {
    margin: 0 29px;
    list-style: none;
  }
  @media (max-width: 1599.98px) {
    #header .menus ul li {
      margin: 0 21px;
    }
  }
  @media (max-width: 991.98px) {
    #header .menus ul li {
      margin: 25px 16px;
    }
  }
  #header .menus ul li a,
  #header .menus ul li button {
    font-weight: bold;
    font-size: 17px;
    line-height: 100%;
    position: relative;
    background: transparent;
    color: #fff;
    border: 0;
    padding: 0;
    box-shadow: none;
    text-decoration: none;
  }
  #header .menus ul li a:hover::before,
  #header .menus ul li a.active::before,
  #header .menus ul li button:hover::before,
  #header .menus ul li button.active::before {
    content: "";
    margin-top: 10px;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 25px;
    height: 3px;
    background: #000000;
    border-radius: 55px;
  }
  #header .links .btn {
    margin-left: 28px;
  }
  @media (max-width: 1599.98px) {
    #header .links .btn {
      margin-left: 20px;
    }
  }
  @media (max-width: 575.98px) {
    #header .links .btn {
      margin-left: 12px;
    }
  }
  @media (max-width: 420px) {
    #header .links .btn {
      padding-left: 8px;
      padding-right: 8px;
      margin-left: 8px;
    }
  }
  #header .links a {
    color: #fff;
  }
  #header .links a,
  #header .links button {
    font-weight: bold;
    font-size: 17px;
    line-height: 100%;
  }
  @media (max-width: 575.98px) {
    #header .links a,
    #header .links button {
      font-size: 14px;
    }
  }
  @media (max-width: 420px) {
    #header .links a,
    #header .links button {
      margin-left: 12px;
    }
  }
  @media (max-width: 375px) {
    #header .links a,
    #header .links button {
      font-size: 12px;
    }
  }
  #footer {
    background: #000;
    padding: 0;
  }
  @media (max-width: 991.98px) {
    #footer {
      padding: 0 30px;
    }
  }
  #footer .wrapper {
    background-position: center right;
    background-repeat: no-repeat;
    background-size: aoto 100%;
    display: flex;
  }
  @media (max-width: 767.98px) {
    #footer .wrapper {
      flex-wrap: wrap;
      background-position: right bottom;
      background-size: auto 60%;
    }
  }
  #footer .wrapper .info {
    width: 100%;
    background: #232323;
    padding: 50px;
    display: flex;
    justify-content: space-around;
  }
  @media (max-width: 575.98px) {
    #footer .wrapper .info .sub-content {
      width: 100%;
    }
  }
  #footer .wrapper .info .sub-content .logo {
    max-height: 200px;
  }
  @media (max-width: 1599.98px) {
    #footer .wrapper .info .sub-content .logo {
      height: 200px;
    }
  }
  @media (max-width: 575.98px) {
    #footer .wrapper .info .sub-content .logo {
      height: 180px;
    }
  }
  @media (max-width: 375px) {
    #footer .wrapper .info .sub-content .logo {
      height: 175px;
    }
  }
  #footer .wrapper .info .menu-items {
    display: flex;
    justify-content: flex;
    gap: 2rem;
  }
  #footer .wrapper .info .menu-items .right-arrow {
    display: none;
  }
  #footer .wrapper .info .f-icons {
    display: flex;
    gap: 0.75rem;
    margin-bottom: 1rem;
    color: rgba(255, 255, 255, 0.5);
  }
  #footer .wrapper .info .f-icons .face:hover {
    color: #000000;
  }
  #footer .wrapper .info .f-icons .insta:hover {
    color: #000000;
  }
  #footer .wrapper .info .f-icons .you:hover {
    color: #000000;
  }
  #footer .wrapper .info .f-icons .envelop:hover {
    color: #000000;
  }
  @media (max-width: 991.98px) {
    #footer .wrapper .info {
      padding: 35px;
    }
  }
  @media (max-width: 767.98px) {
    #footer .wrapper .info {
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
    }
    #footer .wrapper .info .menu-items {
      display: block;
    }
    #footer .wrapper .info .menu-items .right-arrow {
      display: block;
    }
  }
  #footer .wrapper .info .logo {
    margin-bottom: 25px;
    max-width: 100%;
  }
  #footer .wrapper .info .info-text,
  #footer .wrapper .info .lable-text {
    font-size: 13px;
    line-height: 20px;
    color: #919195;
  }
  #footer .wrapper .info .info-text {
    margin-bottom: 50px;
  }
  #footer .wrapper .info .lable-text {
    margin-bottom: 20px;
  }
  #footer .wrapper .info .subsctibe {
    background: #1a1a1a;
    border-radius: 10px;
    display: flex;
  }
  #footer .wrapper .info .subsctibe .form-control {
    border-radius: 10px;
    height: 50px;
    font-weight: bold;
    background: inherit;
    color: #919195;
    border: 0;
    line-height: 18px;
    box-shadow: none;
  }
  @media (min-width: 767.98px) {
    #footer .wrapper .info .subsctibe .form-control {
      font-size: 14px;
    }
  }
  #footer .wrapper .info .subsctibe .btn {
    white-space: nowrap;
    height: 50px;
    border-radius: 10px;
  }
  #footer .wrapper .f-menus {
    padding-left: 40px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media (max-width: 1299.98px) {
    #footer .wrapper .f-menus {
      padding-top: 40px;
      padding-bottom: 40px;
      padding-left: 70px;
    }
  }
  @media (max-width: 991.98px) {
    #footer .wrapper .f-menus {
      padding-top: 35px;
      padding-bottom: 35px;
      padding-left: 40px;
    }
  }
  @media (max-width: 767.98px) {
    #footer .wrapper .f-menus {
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
      padding: 35px 0px;
    }
  }
  #footer .wrapper .f-menus h3 {
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 13px;
  }
  #footer .wrapper .f-menus .menu-item {
    margin-bottom: 12px;
  }
  #footer .wrapper .f-menus .menu-item a {
    display: inline-flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.5);
  }
  #footer .wrapper .f-menus .menu-item a svg {
    margin-left: 7px;
  }
  #footer .wrapper .f-menus .b-links {
    margin-top: 35px;
    margin-bottom: 20px;
  }
  #footer .wrapper .f-menus .b-links a {
    font-size: 14px;
    line-height: 16px;
    text-decoration-line: underline;
    color: #5f6062;
    margin-right: 30px;
  }
  #footer .wrapper .f-menus .copy-text {
    font-size: 14px;
    line-height: 16px;
    color: #5f6062;
    margin-bottom: 0;
  }
  .f-icons {
    display: flex;
    gap: 0.75rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: rgba(43, 37, 37, 0.5);
  }
  .f-icons .face:hover {
    color: #000;
  }
  .f-icons .insta:hover {
    color: #000;
  }
  .f-icons .you:hover {
    color: #000;
  }
  .btn-primary {
    background-color: #f5522f !important;
    color: #fff !important;
  }
  .btn.arrow {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn.arrow span {
    position: relative;
    transition: all 300ms ease-out;
    will-change: transform;
  }
  .btn.arrow svg {
    position: absolute;
    width: 1.1em;
    right: 0px;
    right: 0rem;
    opacity: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: all 300ms ease-out;
    will-change: right, opacity;
  }
  .btn.arrow:hover span {
    transform: translate3d(-1rem, 0, 0);
  }
  .btn.arrow:hover span svg {
    opacity: 1;
    right: -2rem;
  }
  .tab-box-btn {
    background: #fff;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    display: table;
    margin: 0 auto 30px;
  }
  @media (max-width: 767.98px) {
    .tab-box-btn {
      width: 100%;
    }
  }
  .tab-box-btn .wrapper {
    display: flex;
    padding: 25px 30px;
  }
  @media (max-width: 767.98px) {
    .tab-box-btn .wrapper {
      padding: 14px 15px;
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-evenly;
    }
  }
  .tab-box-btn button {
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    color: #5f6062;
    opacity: 0.7;
    transition: 0.3s;
    background: transparent;
    box-shadow: none;
    padding: 0 30px;
    border: 0;
  }
  @media (max-width: 1439.98px) {
    .tab-box-btn button {
      font-size: 12px;
      padding: 0 12px;
      padding: 4px 12px;
    }
  }
  .tab-box-btn button:hover,
  .tab-box-btn button.active {
    opacity: 1;
    color: #000;
  }
  .package-detail-info .tab-package-btn::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    background: #dfe7e8;
  }
  .tab-package-btn {
    border-radius: 5px;
    margin: 0 0 10px;
    background: #fbfbfb;
    border: 1px solid #f1f1f1;
  }
  @media (max-width: 767.98px) {
    .tab-package-btn {
      width: 100%;
    }
  }
  .tab-package-btn .wrapper {
    display: flex;
    padding: 25px 15px;
  }
  @media (max-width: 767.98px) {
    .tab-package-btn .wrapper {
      padding: 14px 15px;
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-evenly;
    }
  }
  .tab-package-btn button {
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    color: #5f6062;
    opacity: 0.7;
    transition: 0.3s;
    padding: 25px !important;
    background: white;
    border-radius: 5px;
    border: 1px solid #f3f0f0;
    margin-right: 25px;

    box-shadow: none;
    padding: 0 14px;
    border: 0;
    white-space: nowrap;
  }
  @media (max-width: 1439.98px) {
    .tab-package-btn button {
      font-size: 12px;
      padding: 0 12px;
      padding: 10px 12px;
    }
  }
  .tab-package-btn button:hover,
  .tab-package-btn button.active {
    opacity: 1;
    color: #000;
  }
  .link-page {
    display: flex;
    background: #00000054;
    padding: 10px;
    border-radius: 25px;
  }
  .link-page .item {
    font-size: 14px;
    line-height: 100%;
    padding: 0 17px;
    position: relative;
  }
  .link-page .item:first-child::before {
    display: none;
  }
  .link-page .item::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 3px;
    left: 0;
    top: 50%;
    background: #000;
    border-radius: 55px;
    transform: translate(-50%, -50%);
  }
  .link-page .item a {
    color: #fff;
  }
  .link-page .item span {
    color: #fff;
  }
  .h-banner {
    color: #fff;
    position: relative;
    background-size: cover;
    background-position: center;
  }
  @media (max-width: 767.98px) {
    .h-banner {
      padding-top: 80px;
    }
  }
  .h-banner::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(
      72.59% 72.59% at 48.33% 39.54%,
      rgba(0, 0, 0, 0.21) 0%,
      rgba(0, 11, 27, 0.76) 87.01%
    );
    z-index: 1;
  }
  .h-banner .container {
    position: relative;
    z-index: 2;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 80px;
  }
  @media (max-width: 1599.98px) {
    .h-banner .container {
      padding-bottom: 60px;
    }
  }
  @media (max-width: 1439.98px) {
    .h-banner .container {
      max-width: calc(100% - 30px);
      padding-bottom: 45px;
    }
  }
  @media (max-width: 1439.98px) {
    .h-banner .container {
      font-size: 14px;
    }
  }
  @media (max-width: 575.98px) {
    .h-banner .container {
      max-width: 100%;
    }
  }
  .h-banner .container .scrollText {
    position: absolute;
    top: 50%;
    right: 0;
    letter-spacing: 0.5em;
    font-size: 13px;
    line-height: 100%;
    color: #fff;
    opacity: 0.7;
    transform: translateY(-50%) rotate(90deg);
  }
  @media (max-width: 991.98px) {
    .h-banner .container .scrollText {
      display: none;
    }
  }
  .h-banner .container .scrollText img {
    margin-left: 17px;
    transform: rotate(-90deg);
  }
  .h-banner h1 {
    font-size: 58px;
    line-height: 1.152778;
    font-weight: bold;
    max-width: 18em;
    margin-bottom: 55px;
  }
  @media (max-width: 1599.98px) {
    .h-banner h1 {
      font-size: 45px;
      margin-bottom: 18px;
      padding-top: 20px;
    }
  }
  @media (max-width: 1439.98px) {
    .h-banner h1 {
      font-size: 40px;
      padding-top: 0px;
      margin-bottom: 12px;
    }
  }
  @media (max-width: 767.98px) {
    .h-banner h1 {
      font-size: 32px;
    }
  }
  @media (max-width: 575.98px) {
    .h-banner h1 {
      font-size: 26px;
      margin-bottom: 50px;
      line-height: 40px;
    }
  }
  .h-banner .main-dec {
    font-size: 17px;
    line-height: 1.47059;
    opacity: 0.7;
    max-width: 34em;
    margin-bottom: 60px;
  }
  @media (max-width: 1599.98px) {
    .h-banner .main-dec {
      margin-bottom: 45px;
    }
  }
  @media (max-width: 1439.98px) {
    .h-banner .main-dec {
      margin-bottom: 33px;
      font-size: 16px;
    }
  }
  @media (max-width: 767.98px) {
    .h-banner .main-dec {
      font-size: 14px;
    }
  }
  .h-banner .b-bottom-content {
    display: flex;
    justify-content: space-between;
    margin: 0 -8px;
  }
  @media (max-width: 767.98px) {
    .h-banner .b-bottom-content {
      flex-wrap: wrap;
    }
  }
  .h-banner .b-bottom-content .content {
    padding: 0 8px;
    text-align: center;
  }
  @media (max-width: 767.98px) {
    .h-banner .b-bottom-content .content {
      flex: 0 0 25%;
      max-width: 25%;
      margin-top: 16px;
    }
  }
  .h-banner .b-bottom-content .content .item {
    border-radius: 12px;
    cursor: pointer;
    transition: 0.3s;
    padding: 12px;
  }
  @media (max-width: 991.98px) {
    .h-banner .b-bottom-content .content .item {
      padding: 12px 8px;
    }
  }
  .h-banner .b-bottom-content .content .item:hover {
    background: rgba(187, 187, 187, 0.17);
  }
  .h-banner .b-bottom-content .icon {
    margin-bottom: 30px;
    height: 45px;
  }
  @media (max-width: 1599.98px) {
    .h-banner .b-bottom-content .icon {
      height: 40px;
      margin-bottom: 22px;
    }
  }
  @media (max-width: 1439.98px) {
    .h-banner .b-bottom-content .icon {
      height: 30px;
      margin-bottom: 16px;
    }
  }
  @media (max-width: 767.98px) {
    .h-banner .b-bottom-content .icon {
      height: 26px;
      margin-bottom: 14px;
    }
  }
  .h-banner .b-bottom-content .b-title {
    font-size: 16px;
    line-height: 100%;
    color: #fff;
    opacity: 0.7;
    margin-bottom: 0;
    max-width: 130px;
  }
  @media (max-width: 1599.98px) {
    .h-banner .b-bottom-content .b-title {
      font-size: 16px;
    }
  }
  @media (max-width: 1439.98px) {
    .h-banner .b-bottom-content .b-title {
      font-size: 14px;
    }
  }
  @media (max-width: 767.98px) {
    .h-banner .b-bottom-content .b-title {
      font-size: 12px;
    }
  }
  .head-banner {
    color: #fff;
    background-size: cover;
    background-position: center;
    height: 400px;
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 5px;
  }
  @media (max-width: 1599.98px) {
    .head-banner {
      height: 300px;
    }
  }
  @media (max-width: 767.98px) {
    .head-banner {
      height: 250px;
      padding-top: 80px;
    }
  }
  .head-banner::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .head-banner .content {
    position: relative;
    z-index: 2;
  }
  .voucher-banner,
  .upload-banner {
    background-size: cover;
    background-position: center;
    height: 500px;
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 90px;
    position: relative;
    z-index: 1;
  }
  @media (max-width: 1599.98px) {
    .voucher-banner,
    .upload-banner {
      height: 400px;
    }
  }
  @media (max-width: 767.98px) {
    .voucher-banner,
    .upload-banner {
      height: 250px;
      padding-top: 80px;
    }
  }
  .voucher-banner::before,
  .upload-banner::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .voucher-banner .content,
  .upload-banner .content {
    position: relative;
    z-index: 2;
  }
  .register-banner {
    background-size: cover;
    background-position: center;
    height: 500px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 54px;
    position: relative;
    z-index: 1;
  }
  @media (max-width: 767.98px) {
    .register-banner {
      padding-top: 80px;
    }
  }
  .register-banner::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1;
  }
  .register-banner .content {
    position: relative;
    z-index: 2;
  }
  .package-banner {
    position: relative;
    z-index: 1;
    overflow: hidden;
  }
  .package-banner .content {
    position: relative;
    z-index: 2;
  }
  .package-banner .content .slider-wrapper {
    overflow: hidden;
    width: 100vw;
  }
  .package-banner .content .slider-wrapper .owl-dots {
    position: absolute;
    bottom: 94px;
    right: 10%;
    display: none !important;
    text-align: right;
  }
  @media (max-width: 575.98px) {
    .package-banner .content .slider-wrapper .owl-dots {
      bottom: 60px;
    }
  }
  .package-banner .content .render-iamge {
    background-size: cover;
    background-position: center;
    width: 100vw;
    height: 600px;
  }
  @media (max-width: 1199.98px) {
    .package-banner .content .render-iamge {
      height: 500px;
    }
  }
  @media (max-width: 767.98px) {
    .package-banner .content .render-iamge {
      height: 400px;
    }
  }
  .package-banner .content .render-iamge::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .package-banner .b-content {
    position: absolute;
    bottom: 94px;
    left: 0;
    width: 100%;
    z-index: 3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 10%;
  }
  @media (max-width: 767.98px) {
    .package-banner .b-content {
      bottom: 140px;
      left: -20px;
    }
  }
  .package-banner .b-content .name-text {
    margin: 0;
    font-weight: bold;
    font-size: 15px;
    line-height: 100%;
    color: #fff;
    padding: 13px 20px 14px;
    background: rgba(0, 0, 0, 0.68);
    border-radius: 55px;
  }
  .package-banner .link-page {
    position: absolute;
    bottom: 104px;
    left: 50%;
    z-index: 99;
    transform: translateX(-50%);
  }
  @media (max-width: 767.98px) {
    .package-banner .link-page {
      left: 20px;
      transform: none;
    }
  }
  @media (max-width: 420px) {
    .package-banner .link-page .item {
      font-size: 12px;
    }
  }
  .search-destination {
    max-width: 584px;
  }
  .search-destination .wrapper {
    background: #fff;
    padding: 11px;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    display: flex;
    align-items: center;
  }
  .search-destination .wrapper .inp-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
  }
  @media (min-width: 575.98px) {
    .search-destination .wrapper .inp-wrapper {
      align-items: center;
    }
  }
  @media (max-width: 575.98px) {
    .search-destination .wrapper .inp-wrapper {
      flex-direction: column;
    }
  }
  .search-destination .wrapper .inp-wrapper .inps {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }
  @media (max-width: 575.98px) {
    .search-destination .wrapper .inp-wrapper .inps {
      flex-direction: column;
      width: 100%;
    }
    .search-destination .wrapper .inp-wrapper .inps > div {
      width: 100%;
    }
  }
  .search-destination .wrapper .inputs {
    display: flex;
    flex-grow: 1;
    position: relative;
  }
  @media (min-width: 420.98px) {
    .search-destination .wrapper .inputs {
      align-items: center;
    }
  }
  @media (max-width: 420.98px) {
    .search-destination .wrapper .inputs {
      flex-direction: column;
    }
  }
  .search-destination .wrapper .inputs .drop-options {
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
  }
  .search-destination .wrapper .inputs .drop-options .drop-holder {
    background: #fff;
    padding: 10px 0;
    border-radius: 0 0 8px 8px;
  }
  .search-destination .wrapper .inputs .drop-options .drop-holder .drop-wrapper {
    padding: 0 10px;
    max-height: 164px;
    overflow: auto;
  }
  .search-destination
    .wrapper
    .inputs
    .drop-options
    .drop-holder
    .drop-wrapper
    button {
    width: 100%;
    border: 0;
    padding: 6px 8px;
    text-align: left;
    background: transparent;
    transition: 0.3s;
  }
  .search-destination
    .wrapper
    .inputs
    .drop-options
    .drop-holder
    .drop-wrapper
    button:hover {
    background: #f9f9fa;
  }
  .search-destination .wrapper .des {
    display: flex;
    align-items: center;
  }
  .search-destination .wrapper .form-control {
    border: 0;
    box-shadow: none;
  }
  @media (max-width: 767.98px) {
    .search-destination .wrapper .form-control::placeholder {
      font-size: 14px;
    }
  }
  @media (min-width: 575.98px) {
    .search-destination .wrapper .form-control.keywords {
      width: 222px;
    }
  }
  @media (min-width: 767.98px) {
    .search-destination .wrapper .form-control.keywords {
      width: 250px;
    }
  }
  @media (max-width: 575.98px) {
    .search-destination .wrapper .form-control.keywords {
      padding-left: 35px;
    }
  }
  @media (max-width: 575.98px) {
    .search-destination .wrapper .btn-wrapper {
      width: 100%;
    }
  }
  .search-destination .wrapper .btn {
    width: 115px;
    height: 47px;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }
  @media (max-width: 575.98px) {
    .search-destination .wrapper .btn {
      width: 100%;
      margin-top: 16px;
    }
  }
  .sign-up-section {
    margin-top: -200px;
    position: relative;
    z-index: 2;
    padding: 0 15px 150px;
  }
  .main-form-card {
    background: #fff;
    max-width: 689px;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.05);
    border-radius: 25px;
    margin: auto;
    padding: 50px 30px;
  }
  .main-form {
    max-width: 295px;
    margin: auto;
  }
  .main-form .title {
    font-weight: bold;
    font-size: 36px;
    line-height: 45px;
    color: #262626;
    margin-bottom: 30px;
  }
  .main-form .label {
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #262626;
    margin-bottom: 8px;
  }
  .main-form .icon-input {
    position: relative;
  }
  .main-form .icon-input svg {
    position: absolute;
    left: 25px;
    top: 50%;
    transform: translateY(-50%);
  }
  .main-form .react-tel-input .form-control {
    width: 100%;
  }
  .main-form .inp-wrapper {
    margin-bottom: 20px;
  }
  .main-form .PhoneInput,
  .main-form .form-control {
    background: #f6f6f7;
    border-radius: 13px;
    height: 55px;
    border: 0;
    padding-left: 59px;
    color: #5f6062;
  }
  @media (min-width: 767.98px) {
    .main-form .PhoneInput,
    .main-form .form-control {
      font-size: 14px;
    }
  }
  .main-form .flag-dropdown {
    border: 0;
    left: 8px;
    background: transparent !important;
  }
  .main-form .react-tel-input .selected-flag:hover {
    background: transparent;
  }
  .main-form .btn {
    margin-top: 20px;
    width: 100%;
    height: 50px;
    border-radius: 13px;
  }
  .main-form .already {
    font-size: 15px;
    line-height: 19px;
    text-align: center;
    margin-top: 26px;
    margin-bottom: 0;
  }
  .spendwinbanner {
    padding: 10px;
    margin: 10px 0;
  }
  .spendwinhomeimg {
    border-radius: 10px;
  }
  @media only screen and (max-width: 600px) {
    .owl-prev {
      left: 15px;
    }
    .owl-next {
      right: 15px;
    }
  }
  .book-slider-wrapper {
    overflow: hidden;
  }
  .book-slider {
    width: 102vw;
  }
  @media (max-width: 575.98px) {
    .book-slider {
      width: 100vw;
    }
  }
  .book-slider .owl-carousel {
    display: flex;
    flex-direction: column;
  }
  .book-slider .owl-carousel .owl-stage-outer {
    order: 2;
  }
  @media (max-width: 575.98px) {
    .book-slider .owl-carousel .owl-stage-outer {
      order: 1;
    }
  }
  .book-slider .owl-carousel .owl-nav {
    order: 1;
    text-align: right;
    padding-right: calc(3vw + 7.5%);
    margin-top: 0;
    margin-bottom: 91px;
  }
  @media (max-width: 575.98px) {
    .book-slider .owl-carousel .owl-nav {
      order: 2;
      display: none;
    }
  }
  .book-slider .owl-carousel .owl-dots {
    order: 3;
    text-align: right;
    padding-right: calc(3vw + 7.5%);
  }
  .book-SlideCard-wrapper {
    padding-left: 60px;
  }
  @media (max-width: 1599.98px) {
    .book-SlideCard-wrapper {
      padding-left: 45px;
    }
  }
  @media (max-width: 575.98px) {
    .book-SlideCard-wrapper {
      padding: 0 15px;
    }
  }
  .book-SlideCard {
    width: 100%;
    padding-top: 151.905%;
    position: relative;
    border-radius: 25px;
    overflow: hidden;
  }
  .book-SlideCard .wrapper {
    width: 100%;
    padding-top: 130%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: inherit;
    overflow: hidden;
    transition: 0.5s;
    background-color: #f3f3f3;
  }
  @media (max-width: 575.98px) {
    .book-SlideCard .wrapper {
      padding-top: inherit;
    }
  }
  .book-SlideCard .wrapper:hover {
    padding-top: inherit;
  }
  .book-SlideCard .wrapper:hover .buy {
    height: 47px;
    margin-top: 21px;
  }
  .book-SlideCard .wrapper:not(:hover) .buy {
    height: 0;
    margin-top: 0;
  }
  .book-SlideCard .wrapper .render-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    object-fit: cover;
  }
  .book-SlideCard .wrapper .dark-layer {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) -5.56%,
      rgba(0, 0, 0, 0.75) 119.7%
    );
  }
  .book-SlideCard .wrapper .content {
    z-index: 3;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 0 30px 25px;
  }
  .book-SlideCard .wrapper .content .title {
    font-family: Roboto, MetaPro, "Trebuchet MS";
    font-weight: bold;
    font-size: 24px;
    line-height: 1.166665;
    color: #fff;
  }
  @media (max-width: 1599.98px) {
    .book-SlideCard .wrapper .content .title {
      font-size: 20px;
    }
  }
  @media (max-width: 991.98px) {
    .book-SlideCard .wrapper .content .title {
      font-size: 18px;
    }
  }
  .book-SlideCard .wrapper .content .dec {
    font-family: Roboto, MetaPro, "Trebuchet MS";
    font-size: 15px;
    line-height: 23px;
    color: rgba(255, 255, 255, 0.7);
    margin: 0;
  }
  @media (max-width: 1599.98px) {
    .book-SlideCard .wrapper .content .dec {
      font-size: 14px;
    }
  }
  @media (max-width: 991.98px) {
    .book-SlideCard .wrapper .content .dec {
      font-size: 13px;
    }
  }
  .book-SlideCard .wrapper .content .buy {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.3s;
  }
  .book-SlideCard .wrapper .content .buy .btn {
    width: 160px;
  }
  .book-SlideCard .wrapper .content .buy .price {
    font-family: Roboto, MetaPro, "Trebuchet MS";
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #fff;
    margin: 0;
  }
  .OffersYouMayLike-section .container {
    max-width: 100%;
  }
  .OffersYouMayLike-section .sec-title {
    margin-bottom: 20px;
  }
  .offer-card-col {
    margin-top: 30px;
  }
  .offer-card {
    background: #fff;
    color: #262626;
    box-shadow: 0px 0px 0px 1px #ccc;
    box-shadow: 0px 0 10px rgba(0, 0, 0, 0.05);
    border-radius: 25px;
    display: flex;
    height: 100%;
    cursor: pointer;
    transition: 0.3s;
  }
  @media (max-width: 1599.98px) {
    .offer-card {
      border-radius: 18px;
    }
  }
  @media (max-width: 575.98px) {
    .offer-card {
      border-radius: 15px;
    }
  }
  .offer-card.c-light .logo-wrapper::before,
  .offer-card.c-light .logo-wrapper::after {
    background: #fff;
  }
  .offer-card .logo-wrapper {
    flex: 0 0 160px;
    max-width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 21px;
    position: relative;
    border-right: 2px dashed #ccc;
  }
  @media (max-width: 1599.98px) {
    .offer-card .logo-wrapper {
      flex: 0 0 120px;
      max-width: 120px;
      padding: 18px 15px;
    }
  }
  @media (max-width: 575.98px) {
    .offer-card .logo-wrapper {
      padding: 15px;
      flex: 0 0 90px;
      max-width: 90px;
    }
  }
  .offer-card .logo-wrapper::before,
  .offer-card .logo-wrapper::after {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;
    left: 100%;
    border-radius: 100%;
    background: #f9f9fa;
    margin-left: 1px;
  }
  .offer-card .logo-wrapper::before {
    top: 100%;
    transform: translate(-50%, -50%);
  }
  .offer-card .logo-wrapper::after {
    bottom: 100%;
    transform: translate(-50%, 50%);
  }
  .offer-card .logo-wrapper img {
    max-width: 100%;
    max-height: 100%;
  }
  .offer-card .content {
    flex: 0 0 calc(100% - 160px);
    max-width: calc(100% - 160px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 25px 21px;
  }
  @media (max-width: 1599.98px) {
    .offer-card .content {
      flex: 0 0 calc(100% - 120px);
      max-width: calc(100% - 120px);
      padding: 18px 15px;
    }
  }
  @media (max-width: 575.98px) {
    .offer-card .content {
      flex: 0 0 calc(100% - 90px);
      max-width: calc(100% - 90px);
      padding: 15px;
    }
  }
  .offer-card .brand {
    font-size: 16px;
    line-height: 1.25;
    margin-bottom: 5px;
  }
  @media (max-width: 1599.98px) {
    .offer-card .brand {
      font-size: 14px;
    }
  }
  .offer-card .name {
    font-weight: bold;
    font-size: 24px;
    line-height: 1.25;
    margin-bottom: 8px;
  }
  @media (max-width: 1599.98px) {
    .offer-card .name {
      font-size: 18px;
    }
  }
  .offer-card .title {
    font-size: 14px;
    margin-bottom: 8px;
  }
  .offer-card .decription {
    font-size: 14px;
    margin-bottom: 8px;
    color: #c3c3c3;
  }
  .offer-card .validTill {
    font-size: 18px;
    line-height: 100%;
    margin-bottom: 0;
    color: #c3c3c3;
    text-align: right;
  }
  @media (max-width: 1599.98px) {
    .offer-card .validTill {
      font-size: 13px;
    }
  }
  .book-youre-experience-section-homepage {
    padding: 0px 0px 80px;
  }
  @media (max-width: 1599.98px) {
    .book-youre-experience-section-homepage {
      padding: 0px 0px 80px;
    }
  }
  @media (max-width: 1439.98px) {
    .book-youre-experience-section-homepage {
      padding: 0px 0px 80px;
    }
  }
  @media (max-width: 1299.98px) {
    .book-youre-experience-section-homepage .container {
      max-width: 100%;
    }
  }
  .book-youre-experience-section-homepage .sec-title {
    margin-bottom: 17px;
    text-transform: capitalize;
  }
  .book-youre-experience-section-homepage .owl-dots {
    display: none !important;
  }
  .book-youre-experience-section {
    padding: 80px 0 150px;
  }
  @media (max-width: 1599.98px) {
    .book-youre-experience-section {
      padding: 60px 0 112px;
    }
  }
  @media (max-width: 1439.98px) {
    .book-youre-experience-section {
      padding: 45px 0 84px;
    }
  }
  @media (max-width: 1299.98px) {
    .book-youre-experience-section .container {
      max-width: 100%;
    }
  }
  .book-youre-experience-section .sec-title {
    margin-bottom: 42px;
    text-transform: capitalize;
  }
  .book-exp-card {
    margin-top: 30px;
    width: 100%;
    padding-top: 39.509%;
    background-color: #fff;
    border-radius: 25px;
    position: relative;
    overflow: hidden;
    min-height: 300px;
    color: #fff;
    cursor: pointer;
  }
  .book-exp-card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      252.91deg,
      rgba(0, 0, 0, 0.1) -6.51%,
      rgba(0, 0, 0, 0.8) 107.51%
    );
    z-index: 2;
  }
  .book-exp-card .render-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    object-fit: cover;
  }
  .book-exp-card .content {
    padding: 30px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .book-exp-card .content .top-content {
    display: flex;
    justify-content: space-between;
  }
  .book-exp-card .content .top-content p {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }
  .book-exp-card .content .top-content p span {
    opacity: 0.7;
  }
  .book-exp-card .content .top-content .rating {
    padding: 15px;
    width: auto;
    height: 43px;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .book-exp-card .content .top-content .rating img {
    margin-right: 10px;
    height: 20px;
  }
  .book-exp-card .content .bottom-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @media (max-width: 575.98px) {
    .book-exp-card .content .bottom-content {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .book-exp-card .content .bottom-content .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 1.25;
  }
  @media (max-width: 1599.98px) {
    .book-exp-card .content .bottom-content .title {
      font-size: 24px;
    }
  }
  @media (max-width: 1439.98px) {
    .book-exp-card .content .bottom-content .title {
      font-size: 20px;
    }
  }
  @media (max-width: 991.98px) {
    .book-exp-card .content .bottom-content .title {
      font-size: 14px;
      max-width: 100%;
      padding-right: 0 !important;
    }
  }
  .book-exp-card .content .bottom-content .price {
    background-color: #000000;
    padding: 10px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 24px;
    line-height: 1.166665;
    margin-bottom: 0;
    white-space: nowrap;
  }
  @media (max-width: 1599.98px) {
    .book-exp-card .content .bottom-content .price {
      font-size: 20px;
    }
  }
  @media (max-width: 1439.98px) {
    .book-exp-card .content .bottom-content .price {
      font-size: 18px;
    }
  }
  @media (max-width: 991.98px) {
    .book-exp-card .content .bottom-content .price {
      font-size: 13px;
    }
  }
  @media (max-width: 575.98px) {
    .book-exp-card .content .bottom-content .price {
      text-align: right;
      width: auto;
    }
  }
  .book-exp-card .content .bottom-content-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @media (max-width: 575.98px) {
    .book-exp-card .content .bottom-content-btn {
      flex-direction: column;
      align-items: flex-end;
    }
  }
  .book-exp-card .content .bottom-content-btn .title {
    font-weight: bold;
    font-size: 36px;
    line-height: 1.25;
  }
  @media (max-width: 1599.98px) {
    .book-exp-card .content .bottom-content-btn .title {
      font-size: 27px;
    }
  }
  @media (max-width: 1439.98px) {
    .book-exp-card .content .bottom-content-btn .title {
      font-size: 20px;
      max-width: 80%;
    }
  }
  @media (max-width: 991.98px) {
    .book-exp-card .content .bottom-content-btn .title {
      font-size: 14px;
      max-width: 100%;
      padding-right: 0 !important;
    }
  }
  .book-exp-card .content .bottom-content-btn .price {
    background-color: #000000;
    padding: 10px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 24px;
    line-height: 1.166665;
    margin-bottom: 0;
    white-space: nowrap;
  }
  @media (max-width: 1599.98px) {
    .book-exp-card .content .bottom-content-btn .price {
      font-size: 20px;
    }
  }
  @media (max-width: 1439.98px) {
    .book-exp-card .content .bottom-content-btn .price {
      font-size: 18px;
    }
  }
  @media (max-width: 991.98px) {
    .book-exp-card .content .bottom-content-btn .price {
      font-size: 13px;
    }
  }
  @media (max-width: 575.98px) {
    .book-exp-card .content .bottom-content-btn .price {
      text-align: right;
      width: auto;
    }
  }
  .uploadSection {
    position: relative;
    z-index: 2;
    margin-top: -50px;
    padding: 0 30px 80px;
  }
  .uploadSection .UploadCard {
    margin: 0 auto;
    max-width: 800px;
    max-width: 1410px;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.05);
    border-radius: 25px;
    padding: 50px;
    background: #fff;
  }
  @media (max-width: 1599.98px) {
    .uploadSection .UploadCard {
      max-width: 1200px;
    }
  }
  .uploadSection .UploadCard .UploadContent h2 {
    font-weight: bold;
    font-size: 36px;
    line-height: 1.25;
    margin-bottom: 20px;
  }
  @media (max-width: 1599.98px) {
    .uploadSection .UploadCard .UploadContent h2 {
      font-size: 26px;
    }
  }
  .uploadSection .UploadCard .UploadContent p {
    font-size: 18px;
    line-height: 1.666666;
    color: #5f6062;
  }
  @media (max-width: 1599.98px) {
    .uploadSection .UploadCard .UploadContent p {
      font-size: 14px;
    }
  }
  .uploadSection .UploadCard .UploadFile .upload-select {
    position: relative;
    padding: 20px;
    cursor: pointer;
    width: 100%;
    height: 240px;
    display: block;
    background: #f9f9fa;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.0005);
    transition: 0.3s;
  }
  .uploadSection .UploadCard .UploadFile .upload-select:hover {
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.001);
  }
  .uploadSection .UploadCard .UploadFile .upload-select .unli {
    text-decoration: underline;
  }
  .uploadSection .UploadCard .UploadFile .upload-select .form-control-file {
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    position: absolute;
    opacity: 0;
  }
  .voucherDetailView {
    position: relative;
    z-index: 2;
    margin-top: -50px;
    padding: 0 30px 80px;
  }
  .voucherDetailView .voucherDetailView-card {
    margin: 0 auto;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.05);
    border-radius: 25px;
    padding: 50px;
    background: #fff;
    max-width: 1410px;
  }
  @media (max-width: 1599.98px) {
    .voucherDetailView .voucherDetailView-card {
      max-width: 1200px;
    }
  }
  @media (max-width: 575.98px) {
    .voucherDetailView .voucherDetailView-card .render-card {
      text-align: center;
    }
  }
  .voucherDetailView .voucherDetailView-card .render-card .logo-wrapper {
    margin-bottom: 16px;
  }
  .voucherDetailView .voucherDetailView-card .render-card .name {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 8px;
  }
  .voucherDetailView .voucherDetailView-card .render-card .title {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 8px;
  }
  .voucherDetailView .voucherDetailView-card .btn {
    border-radius: 8px;
    height: 42px;
  }
  .PackageCard {
    position: relative;
    z-index: 2;
    margin-top: -50px;
    padding: 0 50px 100px;
  }
  @media (max-width: 1599.98px) {
    .PackageCard {
      padding: 0 37px 75px;
    }
  }
  @media (max-width: 767.98px) {
    .PackageCard {
      padding: 0 30px 50px;
    }
  }
  @media (max-width: 575.98px) {
    .PackageCard {
      padding: 0 15px 40px;
    }
  }
  .PackageCard .PackageCard-card {
    margin: 0 auto;
    max-width: 1410px;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.05);
    border-radius: 25px;
    padding: 50px;
    background: #fff;
  }
  @media (max-width: 1599.98px) {
    .PackageCard .PackageCard-card {
      padding: 37px;
    }
  }
  @media (max-width: 991.98px) {
    .PackageCard .PackageCard-card {
      padding: 30px;
    }
  }
  @media (max-width: 575.98px) {
    .PackageCard .PackageCard-card {
      padding: 15px;
    }
  }
  .PackageCard .package-content .content h2 {
    font-weight: bold;
    font-size: 36px;
    line-height: 1.25;
    margin-bottom: 20px;
  }
  @media (max-width: 1599.98px) {
    .PackageCard .package-content .content h2 {
      font-size: 26px;
    }
  }
  .PackageCard .package-content .content p {
    font-size: 18px;
    line-height: 1.666666;
    color: #5f6062;
  }
  @media (max-width: 1599.98px) {
    .PackageCard .package-content .content p {
      font-size: 14px;
    }
  }
  .PackageCard .package-content .content .duration {
    font-size: 15px;
    line-height: 19px;
    color: #262626;
    position: relative;
    padding-left: 30px;
    padding-top: 2px;
  }
  .PackageCard .package-content .content .duration img {
    top: 0;
    left: 0;
    position: absolute;
  }
  .PackageOptionCard {
    border-radius: 20px;
    background: #fff;
    border: 1px solid #e7e7e7;
  }
  .PackageOptionCard .stepsTab {
    padding: 40px 40px 16px;
    border-bottom: 1px solid #e7e7e7;
  }
  @media (max-width: 1599.98px) {
    .PackageOptionCard .stepsTab {
      padding: 30px 30px 12px;
    }
  }
  @media (max-width: 575.98px) {
    .PackageOptionCard .stepsTab {
      padding: 17px 15px 10px;
    }
  }
  .PackageOptionCard .stepsTab .wrapper {
    display: flex;
    margin: 0 -5%;
    position: relative;
  }
  .PackageOptionCard .stepsTab .wrapper .item {
    flex: 0 0 20%;
    max-width: 20%;
    text-align: center;
    position: relative;
  }
  .PackageOptionCard .stepsTab .wrapper .item.item-3 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .PackageOptionCard .stepsTab .wrapper .item.item-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .PackageOptionCard .stepsTab .wrapper .item.item-5 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .PackageOptionCard .stepsTab .wrapper .item.item-6 {
    flex: 0 0 16.66%;
    max-width: 16.66%;
  }
  .PackageOptionCard .stepsTab .wrapper .item.item-7 {
    flex: 0 0 14.2857%;
    max-width: 14.2857%;
  }
  .PackageOptionCard .stepsTab .wrapper .item:last-child .btnWrapper::before {
    display: none;
  }
  .PackageOptionCard .stepsTab .wrapper .item .btnWrapper {
    position: relative;
  }
  .PackageOptionCard .stepsTab .wrapper .item .btnWrapper.active::before,
  .PackageOptionCard .stepsTab .wrapper .item .btnWrapper.activeFull::before {
    content: "";
    position: absolute;
    height: 1px;
    width: 50%;
    top: 50%;
    left: 100%;
    background-color: red;
    transform: translate(-50%, -50%);
  }
  @media (min-width: 991.98px) and (max-width: 1199.98px) {
    .PackageOptionCard .stepsTab .wrapper .item .btnWrapper.active::before,
    .PackageOptionCard .stepsTab .wrapper .item .btnWrapper.activeFull::before {
      width: 70%;
    }
  }
  @media (min-width: 767.98px) and (max-width: 991.98px) {
    .PackageOptionCard .stepsTab .wrapper .item .btnWrapper.active::before,
    .PackageOptionCard .stepsTab .wrapper .item .btnWrapper.activeFull::before {
      width: 60%;
    }
  }
  .PackageOptionCard .stepsTab .wrapper .item .btnWrapper.active::before {
    background: #000000;
  }
  .PackageOptionCard .stepsTab .wrapper .item .btnWrapper.activeFull::before {
    background: linear-gradient(to right, #000000, #f6f6f7);
  }
  .PackageOptionCard .stepsTab .wrapper .item button {
    transition: 0.2s;
    width: 65px;
    height: 65px;
    border: 0;
    border-radius: 100%;
    margin-bottom: 6px;
    background: #f6f6f7;
    color: #9fa0a0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
  @media (max-width: 1599.98px) {
    .PackageOptionCard .stepsTab .wrapper .item button {
      width: 48px;
      height: 48px;
    }
  }
  @media (max-width: 575.98px) {
    .PackageOptionCard .stepsTab .wrapper .item button {
      width: 32px;
      height: 32px;
    }
  }
  @media (max-width: 420px) {
    .PackageOptionCard .stepsTab .wrapper .item button {
      width: 40px;
      height: 40px;
    }
  }
  .PackageOptionCard .stepsTab .wrapper .item button:hover,
  .PackageOptionCard .stepsTab .wrapper .item button.active {
    background: #f5522f;
    color: #fff;
  }
  @media (max-width: 1599.98px) {
    .PackageOptionCard .stepsTab .wrapper .item button svg {
      width: 18px;
      height: 18px;
    }
  }
  @media (max-width: 575.98px) {
    .PackageOptionCard .stepsTab .wrapper .item button svg {
      width: 12px;
      height: 12px;
    }
  }
  @media (max-width: 420px) {
    .PackageOptionCard .stepsTab .wrapper .item button svg {
      width: 11px;
      height: 11px;
    }
  }
  .PackageOptionCard .stepsTab .wrapper .item p {
    font-size: 14px;
    line-height: 18px;
    color: #262626;
    opacity: 0.7;
    margin-bottom: 0;
  }
  @media (max-width: 575.98px) {
    .PackageOptionCard .stepsTab .wrapper .item p {
      font-size: 12px;
    }
  }
  .PackageOptionCard .render-content {
    padding: 32px 40px 40px;
  }
  @media (max-width: 1599.98px) {
    .PackageOptionCard .render-content {
      padding: 22.5px 30px 30px;
    }
  }
  @media (max-width: 767.98px) {
    .PackageOptionCard .render-content {
      padding: 20px 20px;
    }
  }
  @media (max-width: 575.98px) {
    .PackageOptionCard .render-content {
      padding: 15px 15px;
    }
  }
  .PackageOptionCard .usertab {
    margin-bottom: 40px;
  }
  .PackageOptionCard .usertab .row {
    margin-left: -11px;
    margin-right: -11px;
  }
  @media (max-width: 767.98px) {
    .PackageOptionCard .usertab .row {
      margin-left: -4px;
      margin-right: -4px;
    }
  }
  .PackageOptionCard .usertab .row > div {
    padding-left: 11px;
    padding-right: 11px;
  }
  @media (max-width: 767.98px) {
    .PackageOptionCard .usertab .row > div {
      padding-left: 4px;
      padding-right: 4px;
    }
  }
  .PackageOptionCard .usertab .render-input {
    background: #f6f6f7;
    border-radius: 13px;
    padding: 16px 20px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media (max-width: 767.98px) {
    .PackageOptionCard .usertab .render-input {
      padding: 12px 5px;
    }
  }
  @media (max-width: 767.98px) {
    .PackageOptionCard .usertab .render-input .n-wrapper img {
      height: 24px;
      width: 24px;
    }
  }
  .PackageOptionCard .usertab .render-input .n-wrapper svg {
    margin-right: 7px;
  }
  .PackageOptionCard .usertab .render-input .n-wrapper,
  .PackageOptionCard .usertab .render-input .h-wrapper {
    display: flex;
    align-items: center;
  }
  .PackageOptionCard .usertab .render-input .name {
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
  }
  @media (max-width: 767.98px) {
    .PackageOptionCard .usertab .render-input .name {
      font-size: 11px;
    }
  }
  .PackageOptionCard .usertab .render-input .value {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    min-width: 23px;
    text-align: center;
  }
  .PackageOptionCard .usertab .render-input button {
    padding: 0;
    border: 0;
    box-shadow: none;
    background: transparent;
    color: #9fa0a0;
  }
  .PackageOptionCard .usertab .render-input button:hover {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  @media (max-width: 767.98px) {
    .PackageOptionCard .usertab .render-input button svg {
      width: 24px;
      height: 24px;
    }
  }
  .PackageOptionCard .aed-qty-tab table {
    width: 100%;
  }
  @media (max-width: 420px) {
    .PackageOptionCard .aed-qty-tab table.adult-child thead tr {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 4px;
    }
    .PackageOptionCard .aed-qty-tab table.adult-child thead tr th {
      flex: 0 0 50%;
      max-width: 50%;
      padding-bottom: 4px;
    }
    .PackageOptionCard .aed-qty-tab table.adult-child thead tr th.name {
      display: none;
    }
    .PackageOptionCard .aed-qty-tab table.adult-child tbody tr {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 4px;
      border-radius: 4px;
      background: #f5f5f5;
    }
    .PackageOptionCard .aed-qty-tab table.adult-child tbody tr td {
      flex: 0 0 50%;
      max-width: 50%;
      padding-top: 0;
      padding-left: 4px;
      padding-right: 4px;
    }
    .PackageOptionCard .aed-qty-tab table.adult-child tbody tr td:first-child {
      padding-left: 8px;
      padding-bottom: 4px;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .PackageOptionCard .aed-qty-tab table.adult-child tbody tr td .title {
      font-size: 12px;
    }
  }
  .PackageOptionCard .aed-qty-tab table th {
    font-weight: bold;
    font-size: 17px;
    line-height: 1.235292;
    color: #5f6062;
    padding-bottom: 12px;
  }
  @media (max-width: 575.98px) {
    .PackageOptionCard .aed-qty-tab table th {
      font-size: 13px;
    }
  }
  .PackageOptionCard .aed-qty-tab table tbody tr td {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .PackageOptionCard .aed-qty-tab table tbody tr td .title {
    font-weight: bold;
    font-size: 15px;
    line-height: 1.26667;
    color: #262626;
  }
  @media (max-width: 575.98px) {
    .PackageOptionCard .aed-qty-tab table tbody tr td .title {
      font-size: 11px;
    }
  }
  .PackageOptionCard .aed-qty-tab .render-input {
    padding: 16px 25px 15px;
    border: 1px solid #b8b8b8;
    box-sizing: border-box;
    border-radius: 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media (max-width: 767.98px) {
    .PackageOptionCard .aed-qty-tab .render-input {
      padding: 8px 12px;
    }
  }
  @media (max-width: 575.98px) {
    .PackageOptionCard .aed-qty-tab .render-input {
      padding: 6px 8px;
    }
  }
  .PackageOptionCard .aed-qty-tab .render-input .name {
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    color: #262626;
  }
  @media (max-width: 575.98px) {
    .PackageOptionCard .aed-qty-tab .render-input .name {
      font-size: 10px;
    }
  }
  .PackageOptionCard .aed-qty-tab .render-input .value {
    min-width: 15px;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #262626;
  }
  @media (max-width: 575.98px) {
    .PackageOptionCard .aed-qty-tab .render-input .value {
      font-size: 12px;
      line-height: 12px;
    }
  }
  .PackageOptionCard .aed-qty-tab .render-input .handler {
    display: flex;
    align-items: center;
  }
  .PackageOptionCard .aed-qty-tab .render-input button {
    padding: 0;
    border: 0;
    box-shadow: none;
    background: transparent;
    color: #9fa0a0;
  }
  @media (max-width: 575.98px) {
    .PackageOptionCard .aed-qty-tab .render-input button svg {
      width: 16px;
      height: 16px;
    }
  }
  .PackageOptionCard .aed-qty-tab .render-input button.add {
    color: #000000;
  }
  .PackageOptionCard .aed-qty-tab .render-input button:hover {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  .duration-tab .theme-radio {
    margin-bottom: 33px;
  }
  .car-tab {
    margin-bottom: 24px;
  }
  .car-tab table {
    width: 100%;
  }
  @media (max-width: 420px) {
    .car-tab table thead {
      display: block;
    }
    .car-tab table thead tr {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 4px;
    }
    .car-tab table thead tr th {
      flex: 0 0 50%;
      max-width: 50%;
      padding-bottom: 4px;
      display: block;
    }
    .car-tab table thead tr th.title {
      display: none;
    }
    .car-tab table tbody tr {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 4px;
      border-radius: 4px;
      padding: 4px 7px;
      background: #f5f5f5;
    }
    .car-tab table tbody tr td {
      flex: 0 0 50%;
      max-width: 50%;
      padding-top: 0;
      padding-left: 4px;
      padding-right: 4px;
    }
    .car-tab table tbody tr td:first-child {
      padding-bottom: 4px;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .car-tab table tbody tr td .title {
      font-size: 12px;
    }
  }
  .car-tab table th {
    padding-bottom: 8px;
    font-weight: bold;
    font-size: 17px;
    line-height: 1.235292;
    color: #5f6062;
  }
  @media (max-width: 575.98px) {
    .car-tab table th {
      font-size: 13px;
    }
  }
  .car-tab table th.title {
    width: 50%;
    color: #000000;
  }
  .car-tab table tbody td {
    padding-top: 21px;
  }
  .car-tab table tbody td .name {
    max-width: 147px;
    display: inline-block;
  }
  .theme-radio .form-check {
    padding-left: 0;
  }
  .theme-radio label {
    font-weight: bold;
    font-size: 17px;
    line-height: 21px;
    color: #262626;
    cursor: pointer;
    position: relative;
    padding-left: 44px;
  }
  @media (max-width: 767.98px) {
    .theme-radio label {
      padding-left: 28px;
      font-size: 14px;
      line-height: 16px;
    }
  }
  .theme-radio label span {
    border: 2px solid #000000;
    width: 24px;
    height: 24px;
    position: absolute;
    border-radius: 100%;
    left: 0;
  }
  @media (max-width: 767.98px) {
    .theme-radio label span {
      width: 18px;
      height: 18px;
    }
  }
  .theme-radio label span::before {
    border-radius: 100%;
    position: absolute;
    content: "";
    width: 24px;
    height: 24px;
    top: 50%;
    left: 50%;
    background-color: #000000;
    transform: translate(-50%, -50%) scale(0);
    transition: 0.3s;
  }
  @media (max-width: 767.98px) {
    .theme-radio label span::before {
      width: 18px;
      height: 18px;
    }
  }
  .theme-radio label .form-check-input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
  }
  .theme-radio label .form-check-input:checked + span::before {
    transform: translate(-50%, -50%);
  }
  .calander-tab {
    margin-bottom: 30px;
  }
  .calander-tab .react-calendar {
    margin: auto;
    width: 100%;
  }
  .theme-calander .react-calendar {
    background: #f9f9fa;
    border-radius: 12px;
    overflow: hidden;
    border: 0;
  }
  .theme-calander .react-calendar .react-calendar__month-view__weekdays {
    font-family: Roboto, MetaPro, "Trebuchet MS";
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #262626;
    text-transform: initial;
  }
  .theme-calander .react-calendar .react-calendar__month-view__weekdays abbr {
    text-decoration: none;
  }
  .theme-calander .react-calendar .react-calendar__tile--now {
    border: 1px solid #000000;
    border-radius: 40px;
    background: transparent;
  }
  .theme-calander .react-calendar .react-calendar__tile--active {
    background-color: #000000;
    border-radius: 40px;
  }
  .theme-calander .react-calendar .react-calendar__month-view__days {
    font-family: Roboto, MetaPro, "Trebuchet MS";
    font-size: 14px;
    line-height: 24px;
    color: #262626;
  }
  .time-slots .Cal__Container__root {
    overflow: hidden;
    position: relative;
    z-index: 0;
  }
  @media (max-width: 220px) {
    .time-slots .Cal__Container__root {
      height: 320px;
    }
  }
  @media (max-width: 220px) {
    .time-slots .Cal__Container__root .Cal__Container__wrapper {
      position: absolute;
      width: 320px;
      left: 50%;
      top: 50%;
      transform: scale(0.8) translate(-50%, -50%);
      transform-origin: 0 0;
    }
  }
  @media (max-width: 220px) {
    .time-slots .Cal__Container__root .Cal__Container__wrapper {
      transform: scale(0.7) translate(-50%, -50%);
    }
  }
  @media (max-width: 230px) {
    .time-slots .Cal__Container__root .Cal__Container__wrapper {
      transform: scale(0.6) translate(-50%, -50%);
    }
  }
  .vouchers-name {
    text-align: left !important;
  }
  del {
    display: none;
  }
  .package-step-card {
    border: 1px solid #e94661;
    border-radius: 35px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-bottom: 20px;
    width: 100%;
  }
  .package-step-card .Cal__Weekdays__root {
    z-index: 0;
  }
  .package-step-card .step-process {
    display: flex;
    align-items: center;
    padding: 20px 10px;
    border-bottom: 1px solid #e94661;
  }
  .package-step-card .step-process .line {
    align-items: center;
  }
  .package-step-card .step-process .detail {
    width: 42px;
    height: 20px;
    margin-top: 5px;
    justify-content: center;
  }
  .package-step-card .step-process .detail p {
    text-align: center;
    font-size: 12px;
  }
  .package-step-card .step-process .bar {
    flex-grow: 1;
    height: 2px;
    background: #fff;
    box-shadow: 0 2px 8px 0 #eee;
  }
  .package-step-card .step-process .bar.gr {
    background-image: linear-gradient(
      90.01deg,
      #e94661 49.99%,
      rgba(39, 174, 96, 0) 99.98%
    );
  }
  .package-step-card .step-process .bar.fill {
    background: #e94661;
  }
  .package-step-card .step-process .icon {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    box-shadow: 0 2px 8px 0 #eee;
    color: #9c9c9c;
  }
  @media (max-width: 1599.98px) {
    .package-step-card .step-process .icon {
      width: 42px;
      height: 42px;
    }
  }
  @media (max-width: 991.98px) {
    .package-step-card .step-process .icon {
      width: 42px;
      height: 42px;
    }
  }
  .package-step-card .step-process .icon.active {
    background: #e94661;
    box-shadow: none;
    color: #fff;
  }
  @media (max-width: 1599.98px) {
    .package-step-card .step-process .icon svg {
      width: 18px;
      height: 18px;
    }
  }
  @media (max-width: 991.98px) {
    .package-step-card .step-process .icon svg {
      width: 15.5px;
      height: 15.5px;
    }
  }
  .package-step-card .step-process-content {
    padding: 36px 0px 70px;
    position: relative;
    min-height: 328px;
  }
  @media (max-width: 1599.98px) {
    .package-step-card .step-process-content {
      min-height: 246px;
    }
  }
  @media (max-width: 991.98px) {
    .package-step-card .step-process-content {
      min-height: 358px;
    }
  }
  @media (max-width: 374px) {
    .package-step-card .step-process-content {
      padding: 22px 16px 70px;
    }
  }
  .package-step-card .step-process-content .time-slots {
    margin-top: -36px;
    height: 336px;
  }
  .package-step-card .step-process-content .time-slots .Cal__Container__root {
    display: inline;
    z-index: 0;
  }
  .package-step-card .step-process-content .time-slots .Cal__Day__selection {
    background-color: #e94661 !important;
  }
  .package-step-card .step-process-content .time-slots .Cal__Weekdays__root {
    background-color: #e94661 !important;
  }
  .package-step-card
    .step-process-content
    .time-slots
    .Cal__Today__root.Cal__Today__show {
    background-color: #e94661 !important;
  }
  .package-step-card .step-process-content .time-slots .Cal__Today__root {
    background-color: #e94661 !important;
  }
  @media (max-width: 1599.98px) {
    .package-step-card .step-process-content .time-slots .Cal__MonthList__root {
      min-height: 246px;
    }
  }
  @media (max-width: 991.98px) {
    .package-step-card .step-process-content .time-slots .Cal__MonthList__root {
      min-height: 300px;
    }
  }
  @media (max-width: 374px) {
    .package-step-card .step-process-content .time-slots .Cal__MonthList__root {
      padding: 22px 16px 70px;
    }
  }
  @media (min-width: 767.98px) {
    .package-step-card
      .step-process-content
      .time-slots
      .Cal__MonthList__root::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      background: #dfe7e8;
    }
    .package-step-card
      .step-process-content
      .time-slots
      .Cal__MonthList__root::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.5);
      border-radius: 30px;
    }
    .package-step-card
      .step-process-content
      .time-slots
      .Cal__MonthList__root:hover::-webkit-scrollbar-thumb {
      background: #000;
    }
  }
  @media (max-width: 575.98px) {
    .package-step-card .step-process-content .sticky {
      position: fixed !important;
      bottom: 85px !important;
      margin-bottom: 1px !important;
      width: calc(100% - 22px) !important;
    }
  }
  .package-step-card .step-process-content .book {
    position: absolute;
    margin: 0px;
    bottom: 0;
    width: 100%;
    justify-content: center;
  }
  .package-step-card .step-process-content .book .total {
    background-color: #fafafa;
    width: 50%;
    text-align: center;
    height: 70px;
    color: #000;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .package-step-card .step-process-content .book .total p {
    margin: 0px;
    margin-top: 5px;
  }
  .package-step-card .step-process-content .book .next-btn {
    width: 50%;
    border-radius: 0px;
    height: 70px;
    font-weight: 600;
    font-size: 16px;
    line-height: 1;
  }
  .package-step-card .step-process-content .book .next-btn img {
    margin-left: 16px;
  }
  .package-step-card .step-process-content .book .next-btn.active {
    background-color: #e94661;
    border-color: #e94661;
  }
  .counter button {
    width: 33px;
    height: 40px;
    background-color: #f2f4f9;
    border: 0;
  }
  .counter button:first-child {
    border-radius: 6px 0 0 6px;
  }
  .counter button:last-child {
    border-radius: 6px 0 0 6px;
  }
  .counter button:hover {
    background-color: #cfd6e9;
  }
  .counter button:active {
    background-color: #bec8e1;
  }
  .counter input {
    padding: 0;
    width: 40px;
    height: 40px;
    border: 0;
    border-top: 1px solid #f2f4f9;
    border-bottom: 1px solid #f2f4f9;
    text-align: center;
    font-size: 20px;
  }
  .booking-data {
    position: relative;
    overflow-y: scroll;
    text-align: center;
    margin-bottom: 80px;
    height: auto;
  }
  .booking-data .action {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 30%;
    padding: 10px;
    width: 100%;
  }
  .booking-data .action .row {
    width: 100%;
  }
  .booking-data .action .total {
    width: auto;
    border-radius: 10px;
    background-color: #e9ebef;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .booking-data .action .counter {
    margin-bottom: 8px;
    margin-top: 8px;
    font-size: 13px;
    color: #002251;
  }
  .booking-data .action .counter hr {
    width: 100%;
  }
  .booking-data .action .counter .package_title {
    margin-bottom: 6px;
  }
  .booking-data .action .counter .coupon_title {
    color: #000000;
    font-size: 28px;
  }
  .booking-data .action .counter .coupon_content {
    font-size: 12px;
    color: #7a869a;
  }
  .booking-data .action .counter .align-left {
    text-align: left;
  }
  .booking-data .action .counter .align-right {
    text-align: right;
  }
  .booking-data .action .counter p {
    margin: 0px;
  }
  .booking-data .action .counter .package_name {
    font-size: 24px;
    color: #000000;
  }
  .booking-data .action .counter .pending {
    font-size: 14px;
    color: #7a869a;
  }
  .booking-data .action .btn {
    font-size: 13px;
    max-width: 300px;
    min-width: 150px;
    height: 50px;
    border-radius: 100px;
  }
  .booking-data .card-wrap::-webkit-scrollbar {
    display: none;
  }
  @media (min-width: 767.98px) {
    .booking-data::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      background: #dfe7e8;
    }
    .booking-data::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.5);
      border-radius: 30px;
    }
    .booking-data:hover::-webkit-scrollbar-thumb {
      background: #000;
    }
  }
  .coupon-alert {
    height: auto !important;
  }
  .coupon-detail {
    height: 130px;
    background-color: #e4e4e4;
  }
  .coupon-detail .action {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 30%;
    padding: 10px;
    width: 100%;
  }
  .coupon-detail .action .counter .coupon {
    width: 100%;
    margin-left: 5px;
    margin-right: 10px;
    padding: 5px;
    background-color: #fff;
    border-radius: 30px;
    align-items: center;
  }
  .coupon-detail .action .counter .coupon p {
    text-align: center;
    margin: 0px;
  }
  .coupon-detail .action .counter .coupon input {
    width: 100%;
    text-align: start;
    border: 0px;
    font-size: 17px;
  }
  .coupon-detail .action .counter .apply[wobble="1"] {
    animation: wobble 1s 1;
  }
  @keyframes wobble {
    15% {
      position: fixed;
      height: 100%;
      margin: -61px 30px 30px -25px;
    }
    25% {
      position: fixed;
      height: 100%;
      margin: -61px 30px 30px -25px;
    }
    50% {
      position: fixed;
      height: 100%;
      margin: -61px 30px 30px -25px;
    }
    75% {
      position: fixed;
      height: 100%;
      margin: -61px 30px 30px -25px;
    }
    100% {
      position: fixed;
      height: 100%;
      margin: -61px 30px 30px -25px;
    }
  }
  .coupon-detail .action .counter .apply {
    z-index: 1000;
    width: 100%;
    margin: 5px;
    margin-left: 5px;
    margin-right: 10px;
    padding: 10px;
    background-color: #000000;
    border-radius: 30px;
    align-items: center;
  }
  .coupon-detail .action .counter .apply p {
    width: 100%;
    font-size: 20px;
    margin: 0px;
    color: #fff;
    cursor: pointer;
  }
  .subpackage {
    height: 300px !important;
    text-align: center;
    overflow-y: scroll;
  }
  .package-detail-banner {
    min-height: 600px;
    height: 100vh;
    width: 100%;
    background-color: #ababab;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 60px 180px 49px 60px;
    display: flex;
    align-items: flex-end;
    border-radius: 0px 90px 90px 0px;
    position: relative;
  }
  .package-detail-banner::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.43);
    z-index: 1;
    border-radius: inherit;
  }
  .package-detail-banner > div {
    position: relative;
    z-index: 2;
  }
  @media (min-width: 991.98px) {
    .package-detail-banner {
      position: sticky;
      top: 0;
    }
  }
  @media (max-width: 1599.98px) {
    .package-detail-banner {
      padding: 45px 135px 36px 45px;
    }
  }
  @media (max-width: 1599.98px) {
    .package-detail-banner {
      padding: 36px 30px;
    }
  }
  @media (max-width: 1439.98px) {
    .package-detail-banner {
      border-radius: 0px 58px 58px 0px;
    }
  }
  @media (max-width: 991.98px) {
    .package-detail-banner {
      border-radius: 0;
    }
  }
  @media (max-width: 767.98px) {
    .package-detail-banner {
      padding: 20px;
    }
  }
  .package-detail-banner .location-name {
    font-size: 18px;
    line-height: 1.5;
    color: #000;
    margin-bottom: 30px;
  }
  @media (max-width: 1599.98px) {
    .package-detail-banner .location-name {
      font-size: 13px;
      margin-bottom: 22px;
    }
  }
  .package-detail-banner .location-name span {
    padding: 13px 29px;
    background: #fff;
    border-radius: 12px;
  }
  @media (max-width: 1599.98px) {
    .package-detail-banner .location-name span {
      padding: 9px 21px;
      margin-bottom: 22px;
    }
  }
  .package-detail-banner .location-title {
    font-weight: 500;
    font-size: 64px;
    line-height: 2;
    color: #fff;
    font-size: 180% !important;
    -webkit-filter: drop-shadow(5px 5px 5px #222);
    filter: drop-shadow(5px 5px 5px #222);
  }
  @media (max-width: 1599.98px) {
    .package-detail-banner .location-title {
      font-size: 38px;
    }
  }
  @media (max-width: 1439.98px) {
    .package-detail-banner .location-title {
      font-size: 28px;
    }
  }
  .package-detail-info .title {
    font-weight: 500;
    font-size: 36px;
    line-height: 48px;
    color: #333;
    margin-bottom: 4px;
  }
  .package-detail-info .location {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 40px;
    padding-left: 18px;
    background-position: left top 5px;
    background-repeat: no-repeat;
    background-image: url(../images/icons/location-dark.svg);
  }
  .package-detail-info .text-content {
    font-weight: normal;
    font-size: 16px;
    line-height: 2;
    color: #000;
  }
  .package-detail-info .text-content iframe {
    width: 100%;
    border: none !important;
  }
  @media (max-width: 991.98px) {
    .package-detail-info .text-content {
      font-size: 14px;
    }
  }
  .package-detail-info .tab-btns {
    display: flex;
    border-bottom: 2px solid rgba(0, 0, 0, 0.05);
    overflow-x: auto;
    position: relative;
    margin-right: -16px;
    margin-left: -16px;
    padding-right: 16px;
    padding-left: 16px;
  }
  @media (min-width: 767.98px) {
    .package-detail-info .tab-btns::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      background: #dfe7e8;
    }
    .package-detail-info .tab-btns::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.5);
      border-radius: 30px;
    }
    .package-detail-info .tab-btns:hover::-webkit-scrollbar-thumb {
      background: #000;
    }
  }
  .package-detail-info .tab-btns::before {
    content: "";
    position: absolute;
    height: 2px;
    left: 16px;
    bottom: 0;
    background: rgba(0, 0, 0, 0.05);
  }
  .package-detail-info .tab-btns .btn {
    font-size: 18px;
    line-height: 36px;
    color: #343434;
    padding: inherit;
    border: 0;
    outline: none;
    box-shadow: none;
    border-bottom: 2px solid transparent;
    border-radius: 0;
    white-space: nowrap;
  }
  .package-detail-info .tab-btns .btn:not(:first-child) {
    margin-left: 20px;
  }
  @media (max-width: 991.98px) {
    .package-detail-info .tab-btns .btn {
      font-size: 16px;
    }
  }
  .package-detail-info .tab-btns .btn:hover,
  .package-detail-info .tab-btns .btn.active {
    color: #28a745;
    position: relative;
    border-bottom-color: #28a745;
  }
  .package-detail-info .content {
    padding-top: 22px;
  }
  .package-detail-info .content .col-sm-6 {
    width: 50%;
    padding: 5px;
  }
  .package-detail-info .content img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  .package-detail {
    position: relative;
    height: 100%;
  }
  .package-detail h6 {
    border: 1px solid;
    border-color: #e94661;
    margin-left: 22px;
    margin-right: 22px;
  }
  .package-detail .transport {
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .package-detail .transport div {
    width: 60%;
  }
  .package-detail .transport .selected {
    background-color: #e94661;
    color: #fff;
    border-radius: 5px;
  }
  .package-detail .p-image {
    z-index: 1;
    height: 35%;
    top: 0;
    left: 0;
    width: 100%;
    background-position: center;
    background-size: cover;
  }
  .package-detail .card-wrap::-webkit-scrollbar {
    display: none;
  }
  .package-detail .card-wrap {
    background: #fff;
    overflow-y: scroll;
    width: 100%;
    left: 0;
    bottom: 30px;
    z-index: 2;
    height: 40%;
  }
  .package-detail .detai-card {
    padding: 10px 20px 20px;
    border-radius: 15px 15px 0 0;
    height: 100%;
  }
  .package-detail .detai-card .name {
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 4px;
  }
  .package-detail .detai-card .days {
    font-size: 11px;
  }
  .package-detail .detai-card .price {
    font-size: 13px;
    color: #010101;
    margin-bottom: 0;
  }
  .package-detail .detai-card .price-value {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 4px;
  }
  .package-detail .detai-card .dec-title {
    font-size: 17px;
    display: none;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .package-detail .detai-card .dec {
    font-size: 13px;
    color: #484747;
    padding-bottom: 45px;
    line-height: 22px;
  }
  .package-detail .action {
    background-color: #fff;
    padding: 10px 10px 65px;
    border-top: 3px solid #f2f4f9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1020px;
    bottom: 80px;
    width: 100%;
  }
  .btn-coupon {
    height: 33px !important;
    color: #000;
    background-color: #fafafa;
    border-color: #fafafa;
    font-weight: 600;
    font-size: 14px;
    border-radius: 12px;
  }
  .btn-coupon:not(:disabled):not(.disabled):active,
  .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    background-color: #fafafa;
    border-color: #fafafa;
  }
  .btn-coupon:hover {
    background-color: #fafafa;
    border-color: #fafafa;
  }
  .settings {
    height: 50vh;
    width: 100wh;
  }
  .settings .settings_panel {
    bottom: 0;
    width: 100%;
    background-color: #f2f4f9 !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
    max-width: 1020px;
    background-color: #f7f7f7;
    overflow: auto;
  }
  .settings .settings_icon {
    border-radius: 12px;
    padding: 10px;
    border: 0px;
    border-color: #fff;
  }
  .settings #history_icon {
    background-color: rgba(80, 62, 157, 0.15);
  }
  .settings #profile_icon {
    background-color: rgba(251, 109, 58, 0.15);
  }
  .settings #notificaiton_icon {
    background-color: rgba(250, 205, 93, 0.15);
  }
  .settings #help_icon {
    background-color: rgba(151, 151, 151, 0.15);
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }
  .settings #change_pass_icon {
    background-color: rgba(86, 125, 244, 0.15);
  }
  .settings .settings_row {
    margin: 10px;
    margin-top: 20px;
    background: #fff;
    text-align: start;
    display: flex;
    align-items: center;
    padding: 11px;
    border-radius: 9px;
    border-bottom: 2px solid #d3d3d3;
  }
  .settings .settings_text {
    display: flex;
    flex-direction: column;
    margin-left: 25px;
    font-size: 12px;
  }
  .settings .settings_toptext {
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 16px;
    letter-spacing: 1px;
  }
  .settings .settings_bottomtext {
    color: #a3a3a4;
    line-height: 24px;
    font-size: 16;
  }
  .edit-profile .change_password {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
  }
  .edit-profile .image {
    width: 66px;
    height: 70px;
  }
  .edit-profile .image_container {
    border-radius: 50%;
    background-color: rgba(86, 125, 244, 0.1);
    width: 130px;
    height: 130px;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .edit-profile .row {
    display: flex;
    flex-direction: column;
    text-align: start;
    width: 90vw;
    max-width: 900px;
  }
  .edit-profile .input_row {
    display: flex;
    padding: 5px;
    padding-bottom: 10px;
    border-bottom: 1px solid #d3d3d3;
    justify-content: space-between;
  }
  .edit-profile .text_small {
    font-size: 14px;
    color: #789;
    margin-bottom: 2px;
    text-align: start;
    margin-top: 20px;
  }
  .edit-profile .input {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 0;
    border: 0;
    background-color: #fff;
    width: 100%;
  }
  .edit-profile .input::placeholder {
    opacity: 0.5;
    font-size: 16px;
    text-align: left;
  }
  .edit-profile input:focus,
  .edit-profile textarea:focus,
  .edit-profile select:focus {
    outline: none;
  }
  .destinations-package .d-container {
    height: auto;
    background-color: #f2f4f9;
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (max-width: 375px) {
    .destinations-package .d-container {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .destinations-package .menu-text {
    font-size: 17px;
    font-weight: bold;
    padding-bottom: 15px;
  }
  .destinations-package .primary-title {
    font-size: 22px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .destinations-package .arrow {
    margin-right: 20px;
  }
  @media screen and (min-width: 600px) {
    .destinations-package .d-row {
      display: inline-block;
      margin-right: 33px !important;
    }
  }
  .destinations-package .d-row {
    position: relative;
    width: 100%;
    height: 210px;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 40px;
    max-width: 400px;
  }
  .destinations-package .d-row img {
    box-shadow: 0 7px 30px -10px rgba(150, 170, 180, 0.5);
    float: left;
    width: 100%;
    height: 90%;
    z-index: auto;
    border-radius: 12px;
  }
  .destinations-package .d-row .paragraph {
    box-shadow: 0 7px 30px -10px rgba(150, 170, 180, 0.5);
    text-align: center;
    margin-left: 5%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 90%;
    height: 50;
    padding: 5px 0;
    margin-top: 135px;
    border-radius: 12px;
    background-color: #399def;
  }
  .destinations-package .secondary-title {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 0px;
    color: #000;
  }
  .destinations-package .short-description {
    font-size: 12px !important;
  }
  .destinations-package .content-text {
    font-size: 10px;
    display: none;
    color: gray;
  }
  .destinations-package .schedule-text {
    font-size: 15px;
    font-weight: normal;
    color: #000;
    margin-top: 3px;
    margin-bottom: 3px;
  }
  .destinations-package .price-text {
    font-size: 11px;
    font-weight: bold;
    text-align: left;
    color: #fff;
  }
  .destinations-package .price-text-label {
    font-size: 13px;
    font-weight: bold;
    text-align: left;
    color: #fff;
  }
  .location-select {
    margin-left: 1px;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 20px;
  }
  .location-select button {
    background: none;
    border: none;
  }
  .location-select button img {
    height: 32px;
    width: 32px;
  }
  .packages-view-slider-wrapper {
    margin-right: -50px;
  }
  @media (max-width: 1599.98px) {
    .packages-view-slider-wrapper {
      margin-right: -35px;
    }
  }
  @media (max-width: 1299.98px) {
    .packages-view-slider-wrapper {
      margin-right: -20px;
    }
  }
  @media (max-width: 991.98px) {
    .packages-view-slider-wrapper {
      margin-right: -16px;
      margin-left: -16px;
    }
  }
  .categorys-view-slider-wrapper {
    margin-right: -50px;
  }
  @media (max-width: 1599.98px) {
    .categorys-view-slider-wrapper {
      margin-right: -35px;
    }
  }
  @media (max-width: 1299.98px) {
    .categorys-view-slider-wrapper {
      margin-right: -20px;
    }
  }
  @media (max-width: 991.98px) {
    .categorys-view-slider-wrapper {
      margin-right: -16px;
      margin-left: -16px;
    }
  }
  .category-slide-image {
    height: 100vh;
    object-fit: cover;
  }
  .categorys-view-slider {
    display: flex;
    overflow: auto;
    padding-bottom: 12px;
    margin-left: -10px;
    margin-right: -10px;
  }
  @media (max-width: 991.98px) {
    .categorys-view-slider {
      margin-left: 0px;
      margin-right: 0px;
      padding-right: 0;
      padding-left: 8px;
    }
  }
  .categorys-view-slider::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  .categorys-view-slider .owl-carousel {
    margin-left: -10px;
    margin-right: -10px;
  }
  .categorys-view-slider .slide-content-view {
    margin-bottom: 12px;
    flex: 0 0 340px;
    width: 340px;
    padding: 0 10px;
  }
  @media (max-width: 1599.98px) {
    .categorys-view-slider .slide-content-view {
      -moz-box-flex: 0;
      flex: 0 0 320px;
      width: 320px;
    }
  }
  .categorys-view-slider .holder {
    background: #fff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
  }
  .categorys-view-slider .image-wrapper {
    position: relative;
    padding-top: 52.943%;
    width: 100%;
    background: #f2f2f2;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
  }
  .categorys-view-slider .image-wrapper .view-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  .categorys-view-slider .image-wrapper .price {
    font-size: 14px;
    line-height: 18px;
    color: #fff;
    left: 24px;
    bottom: 16px;
    margin-bottom: 0;
    position: absolute;
    z-index: 2;
    padding: 5px 10px;
    border-radius: 5px;
  }
  @media (max-width: 1599.98px) {
    .categorys-view-slider .image-wrapper .price {
      left: 18px;
      bottom: 12px;
    }
  }
  .categorys-view-slider .image-wrapper .price del {
    display: none;
  }
  .categorys-view-slider .d-wrap {
    height: 70px;
  }
  .categorys-view-slider .name {
    margin-bottom: 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    color: #2c2c2c;
  }
  .categorys-view-slider .location {
    font-size: 12px;
    line-height: 18px;
    color: #200e32;
    background-image: url(../images/icons/location.svg);
    background-repeat: no-repeat;
    background-position: top left;
    padding-left: 21px;
    margin-bottom: 0;
  }
  .packages-view-slider {
    display: flex;
    overflow: auto;
    padding-bottom: 12px;
    margin-left: -10px;
    margin-right: -10px;
  }
  @media (max-width: 991.98px) {
    .packages-view-slider {
      margin-left: 0px;
      margin-right: 0px;
      padding-right: 0;
      padding-left: 8px;
    }
  }
  .packages-view-slider::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  .packages-view-slider .owl-carousel {
    margin-left: -10px;
    margin-right: -10px;
  }
  .packages-view-slider .slide-content-view {
    margin-bottom: 12px;
    flex: 0 0 340px;
    width: 340px;
    padding: 0 10px;
  }
  @media (max-width: 1599.98px) {
    .packages-view-slider .slide-content-view {
      -moz-box-flex: 0;
      flex: 0 0 320px;
      width: 320px;
    }
  }
  .packages-view-slider .holder {
    background: #fff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
  }
  .packages-view-slider .image-wrapper {
    position: relative;
    padding-top: 52.943%;
    width: 100%;
    background: #f2f2f2;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
  }
  .packages-view-slider .image-wrapper .view-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  .packages-view-slider .image-wrapper .price {
    font-size: 14px;
    line-height: 18px;
    color: #fff;
    left: 24px;
    bottom: 16px;
    margin-bottom: 0;
    position: absolute;
    z-index: 2;
    padding: 5px 10px;
    border-radius: 5px;
  }
  @media (max-width: 1599.98px) {
    .packages-view-slider .image-wrapper .price {
      left: 18px;
      bottom: 12px;
    }
  }
  .packages-view-slider .d-wrap {
    padding: 10px 5px 12px;
  }
  .packages-view-slider .name {
    margin-bottom: 4px;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #2c2c2c;
  }
  .packages-view-slider .location {
    font-size: 12px;
    line-height: 18px;
    color: #200e32;
    background-image: url(../images/icons/location.svg);
    background-repeat: no-repeat;
    background-position: top left;
    padding-left: 21px;
    margin-bottom: 0;
  }
  .home-banner-slider {
    position: relative;
  }
  @media (min-width: 991.98px) {
    .home-banner-slider {
      position: sticky;
      top: 0;
    }
  }
  .home-banner-slider .owl-stage-outer {
    border-radius: 0px 90px 90px 0px;
  }
  @media (max-width: 1599.98px) {
    .home-banner-slider .owl-stage-outer {
      border-radius: 0px 67px 67px 0px;
    }
  }
  @media (max-width: 1439.98px) {
    .home-banner-slider .owl-stage-outer {
      border-radius: 0px 58px 58px 0px;
    }
  }
  @media (max-width: 991.98px) {
    .home-banner-slider .owl-stage-outer {
      border-radius: 0;
    }
  }
  .home-banner-slider .owl-dots {
    position: absolute;
    bottom: 120px;
    left: 70px;
    width: 250px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 3px;
    display: flex;
  }
  @media (max-width: 1599.98px) {
    .home-banner-slider .owl-dots {
      width: 188px;
      bottom: 90px;
      left: 90px;
    }
  }
  @media (max-width: 575.98px) {
    .home-banner-slider .owl-dots {
      bottom: 37px;
      width: 71px;
      left: 111px;
    }
  }
  @media (max-width: 375px) {
    .home-banner-slider .owl-dots {
      left: 90px;
    }
  }
  .home-banner-slider .owl-dots::before,
  .home-banner-slider .owl-dots::after {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    font-weight: 600;
    font-size: 18px;
  }
  .home-banner-slider .owl-dots::before {
    content: "01";
    right: 100%;
    margin-right: 20px;
  }
  .home-banner-slider .owl-dots::after {
    left: 100%;
    margin-left: 20px;
  }
  .home-banner-slider .owl-dots .owl-dot {
    flex-grow: 1;
  }
  .home-banner-slider .owl-dots .owl-dot span {
    width: 100%;
    height: 3px;
    margin: 0;
    border-radius: 3px;
    background: transparent;
  }
  .home-banner-slider .owl-dots .owl-dot:hover span,
  .home-banner-slider .owl-dots .owl-dot.active span {
    background: #fff;
  }
  .home-banner-slider .owl-nav {
    position: absolute;
    bottom: 101px;
    left: 500px;
  }
  @media (max-width: 1599.98px) {
    .home-banner-slider .owl-nav {
      left: 352px;
      bottom: 73px;
    }
  }
  @media (max-width: 575.98px) {
    .home-banner-slider .owl-nav {
      left: 224px;
      bottom: 23px;
    }
  }
  @media (max-width: 375px) {
    .home-banner-slider .owl-nav {
      left: 210px;
    }
  }
  .home-banner-slider .owl-nav [class*="owl-"] {
    font-size: 38px;
    background-color: transparent !important;
    color: rgba(255, 255, 255, 0.5);
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1;
  }
  @media (max-width: 575.98px) {
    .home-banner-slider .owl-nav [class*="owl-"] {
      font-size: 30px;
    }
  }
  .home-banner-slider .owl-nav [class*="owl-"]:hover {
    color: #fff;
  }
  .home-banner-slider .slider-contnet {
    min-height: 600px;
    height: 100vh;
    width: 100%;
    background-color: #ababab;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 120px;
    display: flex;
    align-items: center;
    position: relative;
  }
  .home-banner-slider .slider-contnet::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.43);
    z-index: 1;
  }
  @media (max-width: 1599.98px) {
    .home-banner-slider .slider-contnet {
      padding: 90px;
    }
  }
  @media (max-width: 1299.98px) {
    .home-banner-slider .slider-contnet {
      padding: 60px;
    }
  }
  @media (max-width: 991.98px) {
    .home-banner-slider .slider-contnet {
      padding: 30px 20px;
      justify-content: center;
    }
  }
  .home-banner-slider .slider-contnet .content {
    position: relative;
    z-index: 2;
    color: #fff;
  }
  @media (max-width: 991.98px) {
    .home-banner-slider .slider-contnet .content {
      text-align: center;
    }
  }
  .home-banner-slider .slider-contnet .content .title {
    font-weight: 600;
    font-size: 54px;
    line-height: 1.38889;
    max-width: 11em;
  }
  @media (max-width: 1599.98px) {
    .home-banner-slider .slider-contnet .content .title {
      font-size: 40.5px;
    }
  }
  @media (max-width: 1299.98px) {
    .home-banner-slider .slider-contnet .content .title {
      font-size: 36px;
    }
  }
  @media (max-width: 991.98px) {
    .home-banner-slider .slider-contnet .content .title {
      margin-left: auto;
      margin-right: auto;
    }
  }
  .home-banner-slider .slider-contnet .content .dec {
    font-size: 24px;
    line-height: 1.5;
    max-width: 28em;
  }
  @media (max-width: 1599.98px) {
    .home-banner-slider .slider-contnet .content .dec {
      font-size: 18px;
    }
  }
  @media (max-width: 1299.98px) {
    .home-banner-slider .slider-contnet .content .dec {
      font-size: 16px;
    }
  }
  .home-banner-slider .slider-contnet .content .btn {
    min-width: 211px;
    height: 58px;
    font-size: 18px;
  }
  @media (max-width: 1599.98px) {
    .home-banner-slider .slider-contnet .content .btn {
      min-width: 158px;
      height: 43px;
      font-size: 14px;
    }
  }
  @media (max-width: 1299.98px) {
    .home-banner-slider .slider-contnet .content .btn {
      min-width: 154px;
      height: 40px;
      font-size: 13px;
    }
  }
  @media (max-width: 767.98px) {
    .home-banner-slider .slider-contnet .content .btn {
      min-width: 211px;
      height: 58px;
      font-size: 18px;
    }
  }
  @media (min-width: 575.98px) {
    .home-banner-slider .slider-contnet .content .btn {
      display: none;
    }
  }
  .order_container {
    display: flex;
    margin-top: 2vh;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
    width: 100%;
    max-width: 1030px;
  }
  .order_row {
    z-index: 2;
    display: flex;
    flex-direction: row;
    padding: 15px;
    background-color: #fff;
    border-radius: 15px;
    margin: 10px 20px 10px 20px;
    flex: 1;
    width: 90%;
    justify-content: space-between;
  }
  .order_left {
    display: flex;
    flex-direction: column;
  }
  .order_right {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    background-color: #f5f8fd;
    border-radius: 15px;
    padding: 10px 20px 10px 15px;
  }
  .invoice_from {
    color: #4b545a;
    font-size: 13px;
    font-weight: 550;
    margin-bottom: 2px;
    margin-right: 10px;
  }
  .settings_row {
    cursor: pointer;
  }
  #autoapplydiscount {
    border: 1px dotted #e67f07;
    display: inline;
    padding: 2px 5px;
  }
  .book {
    margin: 0px;
    bottom: 0;
    width: 100%;
    justify-content: center;
  }
  .book .total {
    background-color: #fafafa;
    width: 50%;
    text-align: center;
    height: 70px;
    color: #000;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .book .total p {
    margin: 0px;
    margin-top: 5px;
  }
  .book .next-btn {
    width: 50%;
    border-radius: 0px;
    height: 70px;
    font-weight: 600;
    font-size: 16px;
    line-height: 1;
  }
  .book .next-btn img {
    margin-left: 16px;
  }
  .book .next-btn.active {
    background-color: #e94661;
    border-color: #e94661;
  }
  .uploadSection .UploadCard {
    padding: 35px 20px;
  }
  .uploadSection {
    padding: 0 10px 80px;
  }
  #mdiv {
    width: 10px;
    height: 50px;
  }
  .mdiv {
    height: 25px;
    width: 2px;
    margin-left: 12px;
    background-color: #000;
    transform: rotate(45deg);
    z-index: 1;
  }
  .md {
    height: 25px;
    width: 2px;
    background-color: #000;
    transform: rotate(90deg);
    z-index: 2;
  }
  .uploadFloatButton {
    position: fixed;
    bottom: 0px;
    background: #fff;
    padding: 20px;
    border-top: 1px solid #ccc;
    -webkit-box-shadow: 0 5px 5px -3px #000, 0 8px 10px 1px #000,
      0 3px 14px 2px #000;
    -moz-box-shadow: 0 5px 5px -3px #000, 0 8px 10px 1px #000, 0 3px 14px 2px #000;
    box-shadow: 0 5px 5px -3px #000, 0 8px 10px 1px #000, 0 3px 14px 2px #000;
    width: 100%;
    left: 0;
    z-index: 9999;
  }
  .btn-voucher-blue {
    color: #fff;
    background-color: #ee714f;
    border-color: #f7633b;
  }
  .upload-banner-image {
    width: 50%;
  }
  @media (max-width: 575.98px) {
    .upload-banner-image {
      width: 100%;
    }
  }
  .Cal__Day__root {
    display: inline-block;
    box-sizing: border-box;
    width: 14.28571%;
    list-style: none;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    user-select: none;
  }
  .Cal__Day__root.Cal__Day__enabled.Cal__Day__highlighted,
  .Cal__Day__root.Cal__Day__enabled:active,
  .Cal__Day__root.Cal__Day__enabled:hover {
    position: relative;
    z-index: 1;
  }
  .Cal__Day__root.Cal__Day__enabled.Cal__Day__highlighted:before,
  .Cal__Day__root.Cal__Day__enabled:active:before,
  .Cal__Day__root.Cal__Day__enabled:hover:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 52px;
    height: 52px;
    margin-top: -26px;
    margin-left: -26px;
    border-radius: 50%;
    background-color: #efefef;
    z-index: -1;
  }
  .Cal__Day__root.Cal__Day__enabled:hover:before {
    opacity: 0.5;
  }
  .Cal__Day__root.Cal__Day__enabled.Cal__Day__highlighted:before,
  .Cal__Day__root.Cal__Day__enabled:active:before {
    opacity: 1;
  }
  .Cal__Day__root:first-child {
    position: relative;
  }
  .Cal__Day__root.Cal__Day__today {
    position: relative;
    z-index: 2;
  }
  .Cal__Day__root.Cal__Day__today > span {
    color: #3d3d3d;
  }
  .Cal__Day__root.Cal__Day__today.Cal__Day__disabled > span {
    color: #aaa;
  }
  .Cal__Day__root.Cal__Day__today:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 52px;
    height: 52px;
    margin-top: -26px;
    margin-left: -26px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px;
    z-index: -1;
  }
  .Cal__Day__root.Cal__Day__today.Cal__Day__disabled:before {
    box-shadow: inset 0 0 0 1px #bbb;
  }
  .Cal__Day__root.Cal__Day__selected {
    position: relative;
  }
  .Cal__Day__root.Cal__Day__selected > .Cal__Day__month,
  .Cal__Day__root.Cal__Day__selected > .Cal__Day__year {
    display: none;
  }
  .Cal__Day__root.Cal__Day__selected:before {
    display: none;
  }
  .Cal__Day__root.Cal__Day__selected .Cal__Day__selection {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 52px;
    height: 52px;
    margin-top: -26px;
    margin-left: -26px;
    border-radius: 50%;
    line-height: 56px;
    z-index: 2;
  }
  .Cal__Day__root.Cal__Day__selected .Cal__Day__selection .Cal__Day__month {
    top: 9px;
  }
  .Cal__Day__root.Cal__Day__selected .Cal__Day__selection .Cal__Day__day {
    position: relative;
    top: 5px;
    font-weight: bold;
    font-size: 18px;
  }
  .Cal__Day__root.Cal__Day__disabled {
    color: #aaa;
    cursor: not-allowed;
  }
  .Cal__Day__month,
  .Cal__Day__year {
    position: absolute;
    left: 0;
    right: 0;
    font-size: 12px;
    line-height: 12px;
    text-transform: capitalize;
  }
  .Cal__Day__month {
    top: 5px;
  }
  .Cal__Day__year {
    bottom: 5px;
  }
  .Cal__Day__range.Cal__Day__selected.Cal__Day__start:after,
  .Cal__Day__range.Cal__Day__selected.Cal__Day__end:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 50%;
    height: 52px;
    margin-top: -26px;
    box-shadow: inset 56px 56px;
  }
  .Cal__Day__range.Cal__Day__selected.Cal__Day__disabled
    .Cal__Day__selection.Cal__Day__selection {
    background-color: #eee !important;
  }
  .Cal__Day__range.Cal__Day__selected.Cal__Day__disabled
    .Cal__Day__selection.Cal__Day__selection
    .Cal__Day__day,
  .Cal__Day__range.Cal__Day__selected.Cal__Day__disabled
    .Cal__Day__selection.Cal__Day__selection
    .Cal__Day__month {
    color: #aaa;
    font-weight: 300;
  }
  .Cal__Day__range.Cal__Day__selected.Cal__Day__start .Cal__Day__selection {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  }
  .Cal__Day__range.Cal__Day__selected.Cal__Day__start:after {
    right: 0;
  }
  .Cal__Day__range.Cal__Day__selected.Cal__Day__start.Cal__Day__end:after {
    display: none;
  }
  .Cal__Day__range.Cal__Day__selected.Cal__Day__betweenRange
    .Cal__Day__selection {
    left: 0;
    right: 0;
    width: 100%;
    margin-left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
  }
  .Cal__Day__range.Cal__Day__selected.Cal__Day__betweenRange .Cal__Day__day {
    top: 0;
    font-size: 16px;
  }
  .Cal__Day__range.Cal__Day__selected.Cal__Day__betweenRange .Cal__Day__month {
    display: none;
  }
  .Cal__Day__range.Cal__Day__selected.Cal__Day__end:after {
    left: 0;
  }
  .Cal__Day__range.Cal__Day__selected.Cal__Day__end .Cal__Day__selection {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    color: inherit !important;
    background-color: #fff !important;
    border: 2px solid;
    box-sizing: border-box;
  }
  .Cal__Day__range.Cal__Day__selected.Cal__Day__end
    .Cal__Day__selection
    .Cal__Day__day {
    top: 4px;
  }
  .Cal__Container__root {
    position: relative;
    display: table;
    z-index: 0;
    z-index: 1;
    font: 14px "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 1.4em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 300;
    outline: none;
    text-align: left;
  }
  .Cal__Container__root.Cal__Container__landscape {
    display: flex;
    z-index: 0;
    flex-direction: row;
  }
  .Cal__Container__root.Cal__Container__landscape .Cal__Container__wrapper {
    position: relative;
    flex-grow: 1;
    z-index: 0;
    overflow: hidden;
    z-index: 1;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .Cal__Container__listWrapper {
    position: relative;
    overflow: hidden;
    background-color: #fff;
  }
  .Cal__Header__root {
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    overflow: hidden;
    min-height: 98px;
    padding: 20px;
    line-height: 1.3;
    font-weight: 400;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  .Cal__Header__root.Cal__Header__landscape {
    align-items: flex-start;
    min-width: 200px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 3px;
  }
  .Cal__Header__root.Cal__Header__landscape
    .Cal__Header__dateWrapper.Cal__Header__day {
    flex-grow: 1;
    height: 76px;
  }
  .Cal__Header__wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    cursor: pointer;
  }
  .Cal__Header__wrapper.Cal__Header__blank {
    height: 58px;
    line-height: 58px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 18px;
    cursor: default;
  }
  .Cal__Header__dateWrapper {
    position: relative;
    display: block;
    overflow: hidden;
    color: rgba(255, 255, 255, 0.5);
    transition: color 0.3s ease;
  }
  .Cal__Header__dateWrapper.Cal__Header__active {
    color: #fff;
  }
  .Cal__Header__dateWrapper.Cal__Header__day {
    height: 38px;
    font-size: 36px;
    line-height: 36px;
    text-transform: capitalize;
  }
  .Cal__Header__dateWrapper.Cal__Header__year {
    height: 20px;
    font-size: 18px;
    line-height: 18px;
  }
  .Cal__Header__date {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .Cal__Header__range {
    display: flex;
    flex-grow: 1;
  }
  .Cal__Header__range .Cal__Header__dateWrapper {
    overflow: visible;
  }
  .Cal__Header__range .Cal__Header__wrapper:first-child:before,
  .Cal__Header__range .Cal__Header__wrapper:first-child:after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 0;
    margin-top: -50px;
    margin-left: -50px;
    border-top: 100px solid transparent;
    border-bottom: 100px solid transparent;
    border-left: 60px solid;
  }
  .Cal__Header__range .Cal__Header__wrapper:first-child:before {
    color: rgba(255, 255, 255, 0.15);
    transform: translateX(1px);
  }
  .Cal__Header__range .Cal__Header__wrapper:last-child {
    margin-left: 60px;
  }
  .Cal__Header__range .Cal__Header__wrapper .Cal__Header__date {
    white-space: nowrap;
    z-index: 1;
  }
  .Cal__Today__root {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    padding: 6px;
    box-sizing: border-box;
    transform: translate3d(0, -100%, 0);
    font-weight: 500;
    line-height: 0;
    z-index: 10;
    cursor: pointer;
    transition: transform 0.3s ease;
    transition-delay: 0.3s;
  }
  .Cal__Today__root.Cal__Today__show {
    transform: translate3d(0, 0, 0);
    transition-delay: 0s;
  }
  .Cal__Today__root.Cal__Today__show .Cal__Today__chevron {
    transition: transform 0.3s ease;
  }
  .Cal__Today__root .Cal__Today__chevron {
    position: absolute;
    top: 50%;
    margin-top: -6px;
    margin-left: 5px;
    transform: rotate(270deg);
    transition: transform 0.3s ease;
  }
  .Cal__Today__root.Cal__Today__chevronUp .Cal__Today__chevron {
    transform: rotate(180deg);
  }
  .Cal__Today__root.Cal__Today__chevronDown .Cal__Today__chevron {
    transform: rotate(360deg);
  }
  .Cal__MonthList__root {
    width: 100% !important;
    background-color: #fff;
    -webkit-overflow-scrolling: touch;
  }
  .Cal__MonthList__root.Cal__MonthList__scrolling > div {
    pointer-events: none;
  }
  .Cal__MonthList__root.Cal__MonthList__scrolling label {
    opacity: 1;
  }
  .Cal__Weekdays__root {
    background: #000000 !important;
    position: relative;
    z-index: 5;
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
    box-shadow: inset 0 -1px rgba(0, 0, 0, 0.04);
  }
  .Cal__Weekdays__day {
    padding: 15px 0;
    flex-basis: 14.28571%;
    flex-grow: 1;
    font-weight: 500;
    text-align: center;
  }
  .Cal__Years__root {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
  }
  .Cal__Years__root:before,
  .Cal__Years__root:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 50px;
    pointer-events: none;
    z-index: 1;
  }
  .Cal__Years__root:before {
    top: 0;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.8) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  .Cal__Years__root:after {
    bottom: 0;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 100%
    );
  }
  .Cal__Years__list {
    box-sizing: border-box;
  }
  .Cal__Years__list.Cal__Years__center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Cal__Years__year {
    display: flex;
    padding: 0 20px;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    -webkit-user-select: none;
    box-sizing: border-box;
  }
  .Cal__Years__year.Cal__Years__withMonths {
    border-bottom: 1px solid #e9e9e9;
  }
  .Cal__Years__year.Cal__Years__withMonths label {
    height: 88px;
    padding-top: 12px;
    box-sizing: border-box;
  }
  .Cal__Years__year label {
    flex-basis: 42%;
  }
  .Cal__Years__year label span {
    flex-shrink: 0;
    color: #333;
  }
  .Cal__Years__year ol {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    font-size: 14px;
  }
  .Cal__Years__year ol li {
    display: flex;
    width: 44px;
    height: 44px;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    list-style: none;
    border-radius: 50%;
    box-sizing: border-box;
    color: #444;
    font-weight: 400;
    text-transform: capitalize;
  }
  .Cal__Years__year ol li.Cal__Years__currentMonth {
    border: 1px solid;
  }
  .Cal__Years__year ol li.Cal__Years__selected {
    position: relative;
    z-index: 1;
    background-color: blue;
    color: #fff !important;
    border: 0;
  }
  .Cal__Years__year ol li.Cal__Years__disabled {
    cursor: not-allowed;
    color: #aaa;
  }
  .Cal__Years__year ol li.Cal__Years__disabled:hover {
    background-color: inherit;
  }
  .Cal__Years__year ol li:hover {
    background-color: #efefef;
  }
  .Cal__Years__year:hover label > span,
  .Cal__Years__year.Cal__Years__active label > span {
    color: inherit;
  }
  .Cal__Years__year:hover,
  .Cal__Years__year.Cal__Years__active {
    position: relative;
    z-index: 2;
  }
  .Cal__Years__year.Cal__Years__active {
    font-size: 32px;
  }
  .Cal__Years__year.Cal__Years__currentYear {
    position: relative;
  }
  .Cal__Years__year.Cal__Years__currentYear label > span {
    min-width: 50px;
    padding-bottom: 5px;
    border-bottom: 3px solid;
  }
  .Cal__Years__year.Cal__Years__currentYear.Cal__Years__active label > span {
    min-width: 85px;
  }
  .Cal__Years__year.Cal__Years__first {
    padding-top: 40px;
  }
  .Cal__Years__year.Cal__Years__last {
    padding-bottom: 40px;
  }
  .Cal__Animation__enter {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
    transition: 0.25s ease;
  }
  .Cal__Animation__enter.Cal__Animation__enterActive {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  .Cal__Animation__leave {
    transform: translate3d(0, 0, 0);
    transition: 0.25s ease;
  }
  .Cal__Animation__leave.Cal__Animation__leaveActive {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  .Cal__Slider__root,
  .Cal__Slider__slide {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .Cal__Slider__root {
    overflow: hidden;
  }
  .Cal__Slider__slide {
    padding: 20px 65px;
  }
  .Cal__Slider__slide:first-child {
    padding-left: 20px;
  }
  .Cal__Slider__wrapper {
    height: 100%;
    transition: transform 0.3s ease;
  }
  .Cal__Slider__arrow {
    position: absolute;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 100%;
    opacity: 0.7;
    cursor: pointer;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
  }
  .Cal__Slider__arrow svg {
    width: 15px;
  }
  .Cal__Slider__arrow:hover {
    opacity: 1;
  }
  .Cal__Slider__arrowRight {
    right: 0;
  }
  .Cal__Slider__arrowLeft {
    left: 0;
    transform: scaleX(-1);
  }
  .Cal__transition__enter {
    opacity: 0;
  }
  .Cal__transition__enterActive {
    opacity: 1;
    transition: opacity 0.3s ease;
  }
  .Cal__transition__leave {
    opacity: 1;
  }
  .Cal__transition__leaveActive {
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  .Cal__Month__rows {
    position: relative;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 50%,
      rgba(0, 0, 0, 0.05) 100%
    );
  }
  .Cal__Month__row {
    padding: 0;
    margin: 0;
  }
  .Cal__Month__row:first-child {
    text-align: right;
  }
  .Cal__Month__row:first-child li {
    background-color: #fff;
    box-shadow: 0 -1px 0 #e9e9e9;
  }
  .Cal__Month__row:nth-child(2) {
    box-shadow: 0 -1px 0 #e9e9e9;
  }
  .Cal__Month__row.Cal__Month__partial:first-child li:first-child {
    box-shadow: 0px -1px 0 #e9e9e9, inset 1px 0 0 #e9e9e9;
  }
  .Cal__Month__row.Cal__Month__partial:last-of-type li {
    position: relative;
    z-index: 1;
  }
  .Cal__Month__label {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    font-size: 30px;
    font-weight: 500;
    z-index: 3;
    pointer-events: none;
    background-color: rgba(255, 255, 255, 0.6);
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  .Cal__Month__label > span {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
  }
  .Cal__Month__label.Cal__Month__partialFirstRow {
    top: 56px;
  }
  .btn-primary {
    border-color: #f5522f;
  }
  