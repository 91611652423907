body {
  margin: 0;
  font-family: Roboto, MetaPro, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  --bg-opacity: 1;
  background-color: #fafafa;
  touch-action: manipulation;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a:hover {
  color: #000000 !important;
}

:root {
  touch-action: pan-x pan-y;
  height: 100%;
}

.pointer {
  cursor: pointer;
}
*:focus {
  border-color: inherit !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #ff0000;
}
.w-40 {
  width: 40% !important;
}
.w-60 {
  width: 60% !important;
}
.text__primary {
  color: #000000 !important;
}
/*  Auth Start */
.auth-card {
  border-radius: 12px !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.auth-card-body {
  width: 35%;
  margin: 0 auto;
  padding: 30px !important;
}
.auth-title {
  font-size: 35px;
  font-weight: 600;
}
.auth-subtitle {
  font-size: 20px;
  font-weight: 400;
}
.auth-label {
  font: size 18px;
  font-weight: 300px;
  color: #000000;
}
.auth-input {
  border-radius: 5px !important;
  height: 45px;
  width: 100% !important;
  /* max-width: 400px; */
  margin-left: auto;
  margin-right: auto;
}
.auth-btn {
  border-radius: 24px !important;
  height: 48px !important;
  outline: none !important;
  width: 40% !important;
}
.error {
  padding: 10px 20px;
  background-color: red;
  border-radius: 6px;
  width: 50%;
  color: white;
}
/* Navbar */
.custom__navbar {
  background-color: #ffffff;
  background: #fff;
  padding: 0px;
}
.custom__width {
  width: calc(100vw - 40px);
}
.sidebar__collapse {
  width: 320px !important;
  background-color: #252525 !important;
  padding: 10px 14px;
  border: none !important;
  overflow: hidden;
}
.sidebar__collapse-child {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
  box-sizing: content-box;
}
.custom__nav {
  padding: 10px !important;
}
.custom__nav .nav-item {
  padding: 30px 25px !important;
  height: 80px !important;
}
.custom__nav .nav-item i {
  color: #b0b5bc !important;
}
.custom__nav .nav-item a {
  font-size: 1.125rem !important;
  font-weight: 600 !important;
  line-height: 1.25;
  color: #b0b5bc !important;
  text-decoration: none !important;
}
.custom__nav .nav-item a:hover {
  color: white !important;
}
.custom__nav .nav-item:hover {
  border-left: 2px solid #b0b5bc !important;
}
.custom__nav__active {
  border-left: 2px solid #ffc93b !important;
}
/* .image__container {
	height: 350px;
} */
.logo {
  height: 150px !important;
  float: left;
}
.menu {
  font-size: 18px;
  font-family: Roboto, MetaPro, "Segoe UI";
  font-weight: 400;
  line-height: 20px;
  color: #faf9f8 !important;
}
.page__title {
  font-family: Roboto, MetaPro, "Segoe UI";
  font-weight: 700;
  font-style: normal;
  font-size: 28px;
  line-height: 37.24px;
  color: black !important;
}
.page__title a {
  text-decoration: none;
  color: inherit;
}
.content__section {
  min-width: 100%;
  height: 100%;
  overflow: hidden;
  background: #fafafa;
}
.content__section-child {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
  box-sizing: content-box;
  position: relative;
}
.cart {
  z-index: 10;
  flex: 1 1 560px;
  margin-right: 5px;
  transform: translateX(-12.5px);
}
.cart__card.card {
  height: calc(100vh - 25px);
}
.card-header {
  background-color: #faf9f8 !important;
}
.cart__card .card-body {
  width: 360px;
}
.card-footer {
  background-color: #faf9f8 !important;
}
.cart__card {
  border: 1px solid #1f6ea9 !important;
}
.cart__title {
  font-family: Roboto, MetaPro, "Segoe UI";
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #262626;
  margin-top: 30px;
  margin-bottom: 10px;
}
.cart__subtitle {
  font-family: Roboto, MetaPro, "Segoe UI";
  font-weight: bold;
  font-size: 10px;
  line-height: 13px;
  color: #000000;
}
.booking__details {
  width: 100%;
  display: grid;
  gap: 20px;
  padding-left: 40px;
  padding-right: 40px;
  grid-template-columns: 5fr 5fr;

  width: 1300px;
  margin: auto;
}
.booking__summary {
  display: flex;
  flex-direction: column;
  justify-content: center;

  background-color: #ffffff;
  height: fit-content;

  border: 0.5px solid #f3f2f1;
  box-sizing: border-box;
  box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1),
    0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  padding: 20px;
  place-content: flex-start;
}
.booking__summary .content {
  width: 100%;
}
.coupon__applied {
  margin-top: 30px;
  padding: 15px 0;
  border-radius: 15px;
  border: 1px dashed green;
  color: green;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.coupon__applied .coupon__code {
  font-weight: bold;
  font-size: 1.3rem;
}
.coupon__discount {
  color: green;
}
.amount__data {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 0;
  gap: 10px;
}
.amount__data > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 0;
  border-bottom: 1.5px solid lightgrey;
}
.amount__data > div:last-child {
  border-bottom: 0px;
}
.cart__list {
  overflow-y: auto;
}
.btn__cancel {
  background: transparent !important;
  color: #000000 !important;
  padding: 10px 22px !important;
  border-color: #000000 !important;
  border-radius: 0 !important;
}
.btn__book {
  background-color: #000 !important;
  color: white !important;
  border-radius: 0 !important;
  padding: 10px 20px !important;
  border-color: #000 !important;
  margin-left: 10px;
}
.btn__total {
  background-color: #000 !important;
  border-color: #000 !important;
  font-weight: bold !important;
  font-size: 22px !important;
  padding: 12px 22px !important;
}
.btn__times {
  background: #000 !important;
  color: white;
  font-size: 12px;
  padding: 4px 5px;
  border-radius: 50%;
}
.cart__item {
  background: #ffffff;
  border: 0.5px solid #f3f2f1;
  box-sizing: border-box;
  box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1),
    0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
  border-radius: 5px;

  padding: 15px 10px;
}
.user__name {
  font-family: Roboto, MetaPro, "Segoe UI";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;

  /* Theme Black */

  color: #262626;
}
.tag__footer {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 60px;
  z-index: 9;
  background: #252525;
  color: white;
}
.tag__footer .tags__wrapper {
  width: calc(100% - 280px);
  height: 100%;
  overflow: hidden;
}
.tag__footer .tags__container {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  gap: 10px;
  padding: 10px 0 30px;

  transform: translateY(0px);
}
.tag__footer .tag__item {
  width: 120px;
  word-wrap: normal;
  white-space: nowrap;
  padding: 10px 15px;
  cursor: pointer;
  color: #a7a7a7;
  text-transform: capitalize;
}
.tag__item.active {
  color: white;
  border-bottom: 2px solid white;
}
.item__title {
  font-family: Roboto, MetaPro, "Segoe UI";
  font-weight: 700;
  font-size: 18px;
  line-height: 23.94px;
  color: #262626;
}
.item__details {
  font-family: Roboto, MetaPro, "Segoe UI";
  font-weight: 500;
  font-size: 12px;
  line-height: 18.62px;
  color: #262626;
}
hr {
  border: 1px solid #ebeae9 !important;
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}
.product__card {
  width: 100% !important;
  height: 200px !important;
  font-size: 24px !important;
}
.search__field {
  background-color: rgba(0, 0, 0, 0) !important;
  border: 0 solid red !important;
  color: black !important;
}
.search__field.user {
  color: black !important;
}
.spinner-border {
  margin-right: 10px;
  height: 1rem !important;
  width: 1rem !important;
}
.coupon__field {
  display: grid;
  grid-template-columns: repeat(3, 3fr);
  gap: 20px;
}
.coupon__field .coupon__item {
  --color: lightgrey;

  width: 100%;
  text-align: center;
  padding: 15px 0;
  cursor: pointer;
  color: var(--color);
  border-radius: 7px;
  border: 2px dashed var(--color);
  user-select: none;
}
.coupon__field .coupon__item.active {
  --color: #000000;
}
.Cal__Day__selection {
  border-radius: 0px !important;
  background-color: #000000 !important;
}
.booking-data {
  margin: auto;
  /* width: calc(100vw - 400px); */
}
.coupon__button-group {
  width: 100%;
  display: grid;
  grid-template-columns: 5fr 5fr;
  gap: 15px;
}
.login__wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  background-color: #fff;
}
.login__container {
  width: 100%;
  max-width: 550px;
  padding: 40px;
  margin: auto;
  background-color: #fff;
}
.login__container h3 {
  font-weight: 400;
  font-size: 1.3rem;
}
.login__container form {
  margin: 10px 0;
}
.login__container label {
  margin-top: 15px;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  color: #000000;
}
.login__container input,
.login__container input[type="password"] {
  height: 45px;
  border-radius: 10px !important;
  border: 0px !important;
  background-color: #e3e3e3;
  font-size: 14px;
}
.login__container input:focus,
.login__container input[type="password"]:focus {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.6);
  background-color: #e3e3e3;
}
.login__container small {
  color: #718096;
  font-size: 13px;
}
.payment__container {
  padding: 10px 45px 10px 25px;
  max-width: 1600px;
  margin: auto;
}
.user__data {
  margin: 20px 0;
  padding: 30px 20px;
  width: 100%;
  border-bottom: 2px solid lightgray;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.user__data--name {
  font-size: 24px;
  font-weight: 500;
}
.user__data b {
  color: #7f7f7f;
}
.user__data--email,
.user__data--mobile {
  color: #3e3e3e;
  font-size: 14px;
}
.user__data .status .purchase__status {
  padding: 5px 7px;
}
.purchase__status {
  --color: black;

  border-width: 1px;
  border-style: solid;
  border-color: var(--color);
  color: var(--color);

  padding: 2px 4px;
  border-radius: 6px;
}
.purchase__status.success {
  --color: green;
}
.purchase__status.failed {
  --color: red;
}
.purchase__status.pending {
  --color: #e0d900;
}
.menu__header li {
  list-style: none;
  color: #000000;
}
.menu__header li a {
  color: inherit;
  text-decoration: none;
}

.aws-btn.header_menu__item .aws-btn__content {
  background: black !important;
  border-radius: 5px;
  color: white;
}
.aws-btn.auth-btn .aws-btn__content {
  background: rgb(0, 0, 0) !important;
  border-radius: 5px;
  color: white;
}
.aws-btn--primary.header_menu__item .aws-btn__wrapper:before {
  background: #000 !important;
}

.aws-btn.cnfm__booking .aws-btn__content {
  color: white !important;
}

.user_name_link {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.order__details--header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.order__details--header small {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}
.order__description {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: row;
}
.order__description .desc {
  width: 100%;
  display: grid;
  grid-template-columns: 200px 5fr;
}
.order__qr {
  /* margin-left: 20rem; */
  height: 250px;
}
.desc > span {
  padding: 5px 0;
}
.desc > span:first-child {
  border-right: 1px solid black;
}
.desc > span:last-child {
  padding-left: 20px;
}
.order__error {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 767px) {
  .auth-card-body {
    padding: 80px 20px !important;
  }
  .auth-input {
    width: 100% !important;
  }
  .auth-btn {
    width: 100% !important;
  }
  .error {
    width: 100%;
  }
}

.payment-method {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 0.7rem;
}

.payment-card {
  background-color: #fff;
  border-radius: 0.5em;
  position: relative;
  width: 40%;
}

.radio {
  display: none;
}

.payment-icon {
  position: absolute;
  bottom: 75%;
  left: 94%;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #000000;
  display: none;
}

.payment-details {
  border: 3px solid #e2ebf6;
  border-radius: 0.5em;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 1rem;
  transition: border-color 0.2s ease-out;
}

.radio:checked ~ .payment-details .payment-icon {
  display: block;
}

.radio:checked ~ .payment-details {
  border: 3px solid #000000;
  color: white;
}

.payment-type {
  color: black;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1em;
  margin-top: 2px;
}

.packages-card {
  width: 100%;
  padding-top: 72%;
  position: relative;
  border-radius: 25px;
  overflow: hidden;
  margin-bottom: 3rem;
  cursor: pointer;
  border-radius: 5px;
}

.packages-card .wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: inherit;
  overflow: hidden;
  transition: 0.5s;
  background-color: #f3f3f3;
}

.packages-card .wrapper .render-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  /* object-fit: cover; */
  /* background-size: cover; */
}

.packages-card .wrapper .dark-layer {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) -5.56%,
    rgba(0, 0, 0, 0.75) 119.7%
  );
}

.packages-card .wrapper .content {
  z-index: 3;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0 30px 25px;
}

.packages-card .wrapper .content .title {
  font-family: "Trebuchet MS";
  font-weight: bold;
  font-size: 24px;
  line-height: 1.166665;
  color: #ffffff;
  text-shadow: 2px 2px #000000;

  @media (max-width: 1599.98px) {
    font-size: 20px;
  }

  @media (max-width: 991.98px) {
    font-size: 18px;
  }
}

.packages-card .wrapper .content .price-package {
  display: flex;
  justify-content: space-between;
}

.packages-card .wrapper .content .price-package .price {
  background-color: #eb6139;
  padding: 10px;
  border-radius: 10px;
  font-weight: 700;
  color: white;
  font-size: 22px;
}

.cart__icon {
  position: relative;
}

.cart__number {
  border-radius: 50%;
  height: 20px;
  width: 20px;
  padding-left: 5px;
  padding-bottom: 23px;
  background-color: #f66344;
  position: absolute;
  right: 50%;
  top: 4%;
  color: white;
}

@media print {
  .header_menu__item,
  .cart__icon,
  .menu__logout,
  .payment__ticket {
    visibility: hidden;
  }
  @page {
    margin-top: 0;
    margin-bottom: 0;
  }
  body {
    padding-top: 72px;
    padding-bottom: 72px;
  }
}

.credit__title {
  margin-left: 50px;
}

.credit__history {
  margin: 0px 50px 50px 50px;
}

.credit__history .py-4 {
  text-align: center;
  border: 1px solid #cdcdcd82;
}

.credit__history .py-3 {
  text-align: center;
  border: 1px solid #cdcdcd82;
}

.credit__history table {
  width: 100%;
  overflow: scroll;
}
